<template>
  <!-- <header> -->
  <div class="container-fluid header-wrapper">
    <div class="container">
      <header role="banner" aria-label="Header" class="pt-2 pb-2">
        <div class="row">
          <div class="col cdc-logo">
            <a href="https://www.cdc.gov">
              <span class="sr-only">
                Centers for Disease Control and Prevention. CDC twenty four
                seven. Saving Lives, Protecting People
              </span>
              <img
                class="d-none d-md-block logo-large"
                src="/assets/img/general_use/masthead-subpage.svg"
              />
            </a>
            <a href="https://www.cdc.gov" tabindex="-1">
              <span class="sr-only">
                Centers for Disease Control and Prevention. CDC twenty four
                seven. Saving Lives, Protecting People
              </span>
              <img class="d-md-none logo-small" src="/assets/img/general_use/masthead-1-col.svg" />
            </a>
          </div>
        </div>
      </header>
    </div>
  </div>
  <!-- </header> -->
</template>

<script>
export default {};
</script>
