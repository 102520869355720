<template>
  <div
    aria-hidden="true"
    :class="[
      'd-print-none row justify-content-center container__button--scroll-to-top',
      !showScrollToTop && 'd-none'
    ]"
  >
    <button @click="scrollToTop" type="button" class="btn btn-primary back-to-top">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import { selectScrollToTop as toTopHome } from '../Home/analyticsHelpers';
import { selectScrollToTop as toTopGraph } from '../Charts/analyticsHelpers';
import { selectScrollToTop as toTopTable } from '../Tables/analyticsHelpers';

export default {
  props: ['scrollTo'],
  data() {
    return {
      showScrollToTop: false
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollHeight = window.scrollY || window.pageYOffset;
      let maxHeight = window.document.documentElement.clientHeight;
      if (scrollHeight > maxHeight) {
        this.showScrollToTop = true;
      } else {
        this.showScrollToTop = false;
      }
    },
    scrollToTop() {
      const { $router } = this;
        switch ($router.currentRoute.value.path) {
        case '/graphs': {
          toTopGraph();
          break;
        }
        case '/tables': {
          toTopTable();
          break;
        }
        default: {
          toTopHome();
        }
      }
      VueScrollTo.scrollTo(this.scrollTo, '1');
    }
  }
};
</script>

<style lang="scss">
.theme-purple .btn-primary,
.theme-purple.btn-primary {
  border-color: white; // this is an override;
}
.back-to-top {
  position: fixed;
  bottom: 10vh;
  right: 0;
  width: 50px;
  border: 3px solid white;
  border-right: none;
  border-radius: 30px 0 0 30px;
}
</style>