import { createApp } from 'vue'
import store from './store';
import App from './App.vue';
import vClickOutside from "click-outside-vue3"
import { VueMasonryPlugin } from 'vue-masonry';
import { createVfm } from 'vue-final-modal'
import router from './router';
import 'whatwg-fetch'; // fetch polyfill for IE
import Popper from "vue3-popper";

var app = createApp(App)

const vfm = createVfm();
app.use(vfm);

app.use(VueMasonryPlugin);
app.use(vClickOutside)
app.use(store);
app.use(router);
app.use(Popper);
app.mount('#app');