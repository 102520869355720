import { doInteraction } from '../../utils/analytics';

// T stands for Table

function getStrat(strat) {
    const lower = strat.toLowerCase();
    const stratMap = {
        "total": 'S',
        race: 'R',
        grade: 'G',
        'sexual identity': 'I',
        'sex of sexual contacts': 'P'
    };
    const code = stratMap[lower];
    if (code) {
        return code;
    } else {
        console.error('Could not find strat: ', strat);
    }
}

// L: Location
export function selectLocation({ locationCode, questionCode, year }) {
    // User changes the location    
    doInteraction(`YRBS|T|L|${locationCode}|${questionCode},${year}`);
}

// Y: Year
export function selectYear({ year, questionCode, strat }) {
    // User changes the year
    const abbreviation = getStrat(strat);
    doInteraction(`YRBS|T|Y|${year}|${questionCode},${abbreviation}`);
}

// CV: Column Variable
export function selectColumnVariable({ strat, questionCode, year }) {
    // User changes the column variable
    const abbreviation = getStrat(strat);
    doInteraction(`YRBS|T|CV|${abbreviation}|${questionCode},${year}`);
}

// N: Navigation
export function selectPageTitle() {
    // User selects "Youth Risk Behavior Survey Results" page title
    doInteraction('YRBS|T|N|Title');
}

export function selectHelp() {
    // User selects "help"
    doInteraction('YRBS|T|N|Help');
}

export function selectAnotherQuestion() {
    // User selects "Select another question" link
    doInteraction('YRBS|T|N|AQ');
}

export function selectOnThisPage({
    scrollToLocation,
    questionCode,
    year,
    strat
}) {
    // User selects "On this Page" link
    const pageLocations = {
        national: 'US', // United States Survey
        state: 'S', // State Survey
        local: 'LUSD' // Local School District Survey
    };
    const loc = pageLocations[scrollToLocation];
    if (loc) {
        const abbreviation = getStrat(strat);
        doInteraction(`YRBS|T|N|${loc}|${questionCode},${year},${abbreviation}`);
    } else {
        console.error('Scroll to location not found: ', scrollToLocation);
    }
}

// G: Graph
export function selectViewGraph({ questionCode, locationCode, year, strat }) {
    // User selects "View Graph" link
    let abbreviation = '';
    if (strat) {
        abbreviation = `,${getStrat(strat)}`;
    }
    doInteraction(
        `YRBS|T|N|G|${questionCode},${locationCode},${year}${abbreviation}`
    );
}

export function selectShareLink({ questionCode, year }) {
    // User selects "Share Link"
    doInteraction(`YRBS|T|N|SL|${questionCode},${year}`);
}

// YOL: Youth Online
export function selectViewInYOL({ questionCode, locationCode, year, strat }) {
    // User selects "View Graph" link
    let abbreviation = '';
    if (strat) {
        abbreviation = `,${getStrat(strat)}`;
    }
    doInteraction(
        `YRBS|T|N|YOL|${questionCode},${locationCode},${year}${abbreviation}`
    );
}

export function selectScrollToTop() {
    // User scrolls to top
    doInteraction('YRBS|T|N|STT');
}

// P: Print
// CSV: CSV
// US: United States
// LOC: locations
export function USPDFExport({ location, questionCode, year }) {
    // User prints/saves - US table
    doInteraction(`YRBS|T|P|${location},${questionCode},${year}`);
}

export function USCSVExport({ location, questionCode, year }) {
    // User exports (CSV) - US table
    doInteraction(`YRBS|T|CSV|${location},${questionCode},${year}`);
}

export function locationsPDFExport({ questionCode, year, strat }) {
    // User prints/saves - locations table i.e. State and LUSD
    const abbreviation = getStrat(strat);
    doInteraction(`YRBS|T|P|Loc|${questionCode},${year},${abbreviation}`);
}

export function locationsCSVExport({ questionCode, year, strat }) {
    // User exports (CSV) - locations table i.e. State and LUSD
    const abbreviation = getStrat(strat);
    doInteraction(`YRBS|T|CSV|Loc|${questionCode},${year},${abbreviation}`);
}

