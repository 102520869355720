<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Other Health Topics
    </title>
    <path
      :fill="color"
      d="M6.12,9.13H4.48a4,4,0,0,0-4,4V33.9a4,4,0,0,0,4,4H6.12Z"
    />
    <path
      :fill="color"
      d="M32.38,9.13H29.53A2,2,0,0,0,28.1,7.65V7.09a5,5,0,0,0-5-5H16.87a5,5,0,0,0-5,5v.56a2,2,0,0,0-1.43,1.48H7.62V37.88H32.38Zm-18.49-2a3,3,0,0,1,3-3h6.25a3,3,0,0,1,3,3v.55a2,2,0,0,0-1.44,1.49H15.33a2,2,0,0,0-1.44-1.49ZM27,26.34H22.57v4.38H17.42V26.34H13V21.19h4.38V16.8h5.15v4.39H27Z"
    />
    <path
      :fill="color"
      d="M35.52,9.13H33.88V37.88h1.64a4,4,0,0,0,4-4V13.11A4,4,0,0,0,35.52,9.13Z"
    /></svg
></template>
<script>
export default {
  props: ['color']
};
</script>
