import { scaleLinear } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { line } from 'd3-shape';
import { createSVG, svgGTranslate } from '../d3.helpers';
import * as d3 from 'd3';
export function createLineGraph(vm) {
    new LineGraph(vm)
        .createXAxis()
        .createYAxis()
        .createLine()
        .createDots()        
        .createDashedLine()
        .createTriangles()        
        .labelForceSimulation()
        .createLegend()
}

export class LineGraph {
    constructor(vm) {
        this.width = vm.width;
        this.scales = this.createScales(vm);
        this.svg = createSVG(vm);
        this.chartData = vm.chartData;
        this.chartData2 = vm.chartData2;
        this.color = vm.color;
        this.margin = vm.margin;
        this.height = vm.height;
        this.location = vm.location
    }

    createScales(vm) {
        const { margin, height, yearRange, width, mainValueRange, chartData2 } = vm;
        const [min, max] = yearRange;
        const [minValue, maxValue] = mainValueRange;

        const x = scaleLinear()
            .domain([min - 1, max + 2])
            .range([0, width - margin.right - margin.left]);
        const y = scaleLinear()
            .domain([minValue, maxValue])
            .range([height - margin.top - margin.bottom, 0]);

        const total = maxValue - minValue;

        let tickValues = [];
        if (chartData2.length > 0) {            
            const split = total / 5;
            let runningTickValue = minValue;
            tickValues.push(runningTickValue)
            for (var index = 0; index < 6; index++) {
                tickValues.push(runningTickValue += split);
            }
        }
        
        return { x, y, tickValues };
    }

    createYAxis() {
        const { svg, scales, width, margin, height } = this;
        // add axis
        svg
            .append('g')
            .call(axisLeft(scales.y).tickValues(scales.tickValues))
            .call(g => {
                g.selectAll('.tick:not(:first-of-type) line')
                    .attr('stroke-opacity', 0.1)
                    .attr('x2', width - margin.right - margin.left + 6)
                    .attr('transform', 'translate(-6, 0)');
                g.selectAll('.tick text').attr('font-size', 17);
            });
        // add Percentage label
        svg
            .append('text')
            .attr('transform', `rotate(-90) translate(-${height / 2}, -50)`)
            .attr('font-size', '15')
            .attr('font-weight', 'bold')
            .text('Percentage (%)');

        return this;
    }

    createXAxis() {
        const { svg, scales, height, margin, chartData, chartData2 } = this;

        const combined = [...chartData, ...chartData2];

        const translation = { x: 0, y: height - margin.top - margin.bottom };
        const tickValues = [];
        for (let i = 0; i < combined.length; i++) {
            const d = combined[i];
            if (i > 0) {
                const prevData = combined[i - 1];
                if (+prevData.Year - +d.Year > 2) {
                    let currYear = +prevData.Year - 2;
                    while (currYear !== +d.Year) {
                        tickValues.push(currYear.toString());
                        currYear -= 2;
                    }
                }
            }
            tickValues.push(d.Year);
        }
        svgGTranslate(svg, translation)
            .call(
                axisBottom(scales.x)
                    .tickValues(tickValues)
                    .tickFormat(format('d'))
            )
            .call(g => {
                g.selectAll('.tick text').attr('font-size', 14);
            });
        return this;
    }

    createLine() {
        const { svg, scales, chartData, color } = this;
        const data = [];
        for (let i = 0; i < chartData.length; i++) {
            const d = chartData[i];
            if (i > 0) {
                const prevData = chartData[i - 1];
                if (+prevData.Year - +d.Year > 2) {
                    let currYear = +prevData.Year - 2;
                    while (currYear !== +d.Year) {
                        data.push([scales.x(currYear.toString()), null]);
                        currYear -= 2;
                    }
                }
            }
            data.push([scales.x(d.Year), scales.y(d.MainValue)]);
        }
        //debugger;
        const _line = line().defined(d => d[1] !== null);
        svg
            .append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', 3)
            .attr('opacity', .7)
            .attr('d', _line(data));
        return this;
    }

    createDots() {
        const { svg, scales, chartData } = this;

        svg
            .append('g')
            .selectAll('dot')
            .data(chartData)
            .enter()
            .append('circle')
            .attr('cx', function (d) {
                return scales.x(d.Year);
            })
            .attr('cy', function (d) {
                return scales.y(d.MainValue);
            })
            .attr('r', 5)
            .attr('fill', 'black');

        return this;
    }

    createDataPoints() {
        const { svg, scales, chartData, color } = this;
        svg
            .append('g')
            .selectAll('dot')
            .data(chartData)
            .enter()
            .append('text')
            .attr('class', 'dataset1')
            .attr('fill', color)
            .attr('font-weight', 'bold')
            .attr('x', function (d) {
                return scales.x(d.Year);
            })
            .attr('y', function (d) {
                return scales.y(d.MainValue);
            })
            .text(function (d) {
                if (d.MainValue === '') return 'N/A';
                return format('.1f')(d.MainValue);
            });
        return this;
    }


    createDashedLine() {
        const { svg, scales, chartData2 } = this;

        if (chartData2.length === 0) return this;
        const data = [];
        for (let i = 0; i < chartData2.length; i++) {
            const d = chartData2[i];
            if (i > 0) {
                const prevData = chartData2[i - 1];
                if (+prevData.Year - +d.Year > 2) {
                    let currYear = +prevData.Year - 2;
                    while (currYear !== +d.Year) {
                        data.push([scales.x(currYear.toString()), null]);
                        currYear -= 2;
                    }
                }
            }
            data.push([scales.x(d.Year), scales.y(d.MainValue)]);
        }
        //debugger;
        const _line = line().defined(d => d[1] !== null);
        svg
            .append('path')
            .datum(data)
            .attr("class", "line")
            .style("stroke-dasharray", "30 10")
            .attr('stroke', '#8c8c8c')
            .attr('fill', 'none')
            .attr('stroke-width', 3)
            .attr('d', _line(data));
        return this;
    }

    createTriangles() {
        const { svg, scales, chartData2 } = this;


        if (chartData2.length === 0) return this;
        svg.selectAll('dot')
            .data(chartData2)
            .enter()
            .append("path")
            .attr('class', 'dataset2')
            .attr('fill', '#4d4d4d')
            .attr("d", d3.symbol().type(d3.symbolTriangle))
            .attr("transform", d => "translate(" + scales.x(d.Year) + "," + scales.y(d.MainValue) + ")");
        return this;
    }

    //saving this one
    overlapCorrection() {
        const { svg, scales } = this;

        var labels = svg.selectAll(".overlap-item")

        //        var radius = 5;
        //        var textOffset = 5;
        //
        labels.each(function (d, i) {
            if (i > 0) {
                var leftItem = this.getBoundingClientRect()

                let conflicts = [];
                labels.each(function (d2, i2) {
                    if (i2 == i) return true;
                    let rightItem = this.getBoundingClientRect();

                    if (!(leftItem.right < rightItem.left ||
                        leftItem.left > rightItem.right ||
                        leftItem.bottom < rightItem.top ||
                        leftItem.top > rightItem.bottom)) {

                        conflicts.push(rightItem);
                    }

                });

                //if (conflicts.length > 0) {
                //    alert(d.MainValue + ': ' + conflicts.length)
                //}


                d3.select(this)
                    .attr('y', function (d) {
                        const offSet = 5
                        return scales.y(d.MainValue) + offSet;
                    })
                    .attr('x', function (d) {
                        const offSet = 0
                        return scales.x(d.Year) + offSet;
                    })


            }
        });
        return this;
    }
    labelForceSimulation() {
        const { svg, scales, chartData, chartData2, color } = this;
        const dataset1 = chartData.map(d => {
            return {
                fx: scales.x(d.Year),
                Year: d.Year,
                MainValue: d.MainValue,
                targetY: scales.y(d.MainValue),
                type: '1',
                direction:'up'
            };
        });
        const dataset2 = chartData2.map(d => {
            return {
                fx: scales.x(d.Year),
                Year: d.Year,
                MainValue: d.MainValue,
                targetY: scales.y(d.MainValue),
                type: '2',
                direction: 'up'
            };
        });

        let combinedDataset = [...dataset1, ...dataset2];

        let filtered = combinedDataset.filter(fds => (fds.type === '1'));
        filtered.map(ds1 => {
            const ds2Result = combinedDataset.filter(ds2 => (ds2.type === '2' && ds1.Year === ds2.Year))

            ds2Result.map(ds2 => {

                if (ds1.targetY > ds2.targetY ) {
                    ds1.direction = 'up';
                    ds2.direction = 'down'                    
                } else {
                    ds1.direction = 'down';
                    ds2.direction = 'up'
                }

            });

        });

        // Set up the force simulation
        const force = d3.forceSimulation()
            .nodes(combinedDataset)
            .force('collide', d3.forceCollide(5))            
            .force('y', d3.forceY(d =>
                d.targetY + (d.direction === 'down' ? -25 : 25))
                .strength(1))
            .stop();
      
        // Execute thte simulation
        for (let i = 0; i < 10; i++) force.tick();
   
        svg
            .append('g')
            .selectAll('dot')
            .data(combinedDataset)
            .enter()
            .append('text')
            .attr('class', 'overlap-item')
            .attr('fill', function (d) {
                return d.type === '1' ? color : 'black'
            })            
            .attr('font-weight', 'bold')

            .attr('x', function (d) {
                return d.x - 12;
            })
            .attr('y', function (d) {
                return d.y;
            })
            .text(function (d) {
                if (d.MainValue === '') return 'N/A';
                return format('.1f')(d.MainValue)// + ' '  + d.type;
            });


        return this;
    }
 
    createLegend() {
        const { location, color } = this;

        //legend - state and local districts
        var local = d3.select("#local-legend-item")
        local.append("line")
            .attr("x1", 0)
            .attr("x2", 50)
            .attr("y1", 10)
            .attr("y2", 10)
            .attr('stroke', color)
            .attr('fill', 'none')
            .attr('stroke-width', 3);

        local.append("text")
            .attr('class', 'legend-local-text')
            .attr("x", 55)
            .attr("y", 10).text(location)
            .style("font-size", "15px")
            .attr("alignment-baseline", "middle");

        local.append("circle")
            .attr("cx", 25)
            .attr("cy", 10)
            .attr("r", 6)
            .style("fill", "black");

        //legend - national
        var national = d3.select("#national-legend-item")
        national.append("line")
            .attr("x1", 0)
            .attr("x2", 50)
            .attr("y1", 10)
            .attr("y2", 10)
            .style("stroke-dasharray", "25 15")
            .attr('stroke', '#8c8c8c')            
            .attr('stroke-width', 3);

        national.append("text").attr('class', 'national-local-text')
            .attr("x", 55)
            .attr("y", 10)
            .text("United States")
            .style("font-size", "15px")
            .attr("alignment-baseline", "middle");

        national.append("path")
            .attr('fill', '#4d4d4d')
            .attr("d", d3.symbol().type(d3.symbolTriangle))
            .attr("transform", () => "translate(" + 25 + "," + 10 + ")");

        return this;
    }
}
