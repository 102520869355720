<template>    
    

      
            <tbody v-bind:id="getTableId()">
                <tr v-for="(stratType, stratName) in tableData" class="tableData"
                    :id="getLocationCode(stratName)"
                    tabindex="-1"
                    :key="stratName">
                    <th scope="row">
                        <div v-if="showLocationLink"
                             class="tool-tip"
                             tabindex="0"
                             @click="showToolTip(stratName)"
                             
                             @keyup.enter="showToolTip(stratName)"
                             @keydown.tab="closeToolTip()">{{ stratName }}</div>
                        <span v-else>{{ stratName }}</span>
                    </th>

                    <DataPercentCI v-for="value in orderRowDataStateLocal(stratType)"
                                   :key="stratName + value.key"
                                   :percent="value.MainValue ? value.MainValue : '—'"
                                   :ci="value.LowCI ? `${value.LowCI} - ${value.HighCI}` : '—'" />
                </tr>
                <tr v-if="medianRange" class="medianRange">
                    <th scope="row">Median</th>
                    <td colspan="2"
                        v-for="value in orderRowDataMedian(medianRange)"
                        :key="'median' + value.key">
                        {{ value.MedianValue }}
                    </td>
                </tr>
                <tr v-if="medianRange" class="medianRange">
                    <th scope="row">Range</th>
                    <td colspan="2" v-for="value in orderRowDataMedian(medianRange)" :key="'range' + value.key">
                        {{ value.MinValue }}{{
 value.MinValue ? ' - ' : ''
                        }}{{ value.MaxValue }}

                        <div v-if="tipData"
                             v-click-outside="tipDataOutsideClickConfig"
                             ref="tooltip"
                             class="tool-tip--item gray-rounded-box"
                             :style="{ top: tipData.y + 'px', left: tipData.x + 'px' }">

                            <div class="link"
                                 @click="graphLink"
                                 @keyup.enter="graphLink"
                                 tabindex="0"
                                 ref="graphLinkItem"
                                 role="link">
                                <i aria-hidden="true" class="fas fa-chart-bar"></i>View Graph
                            </div>
                            <div class="link"
                                 @click="yolLink"
                                 @keyup.enter="yolLink"
                                 id="yol-link-item"
                                 tabindex="0"
                                 role="link">
                                <i aria-hidden="true" class="fas fa-arrow-right"></i>View in Youth
                                Online
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
       
</template>

<script>
    
    import { DataPercentCI } from './tableHelpers';
    import tileDictionary from '../../../utils/tileDictionary';
    import locationDictionary from '../../../utils/locationDictionary';
    import { createLinkToYOL } from '../../../utils/helpers';
    import * as analytics from '../analyticsHelpers';
    export default {
        components: {
            DataPercentCI,
        },
        data() {
            return {
                color: '',
                tipData: null,
                tipDataOutsideClickConfig: {
                    handler: this.closeToolTip,
                    middleware: this.middleware,
                    events: ['dblclick', 'click'],
                }
            };
        },
        props: ['strat', 'tableData', 'headers', 'showLocationLink', 'label', 'medianRange', 'id', 'allLocations', 'textLabel'],
        mounted() {
            const { topicCode } = this.$route.query;
            this.color = topicCode ? tileDictionary[topicCode].color : '';
        },
        watch: {
            tipData(newVal) {
                if (newVal && this.tipData.keyboard) {
                    this.$nextTick(() => {
                        const graphLink = this.$refs.graphLinkItem;
                        graphLink.focus();
                    });
                }

            }
        },
        computed: {
            width() {
                const colWidth = Math.floor(80 / this.headers.length / 2);
                return `width: ${colWidth}%`;
            },
        },
        methods: {            
            handler() {
                this.tipData == null;
            },
            middleware(event) {
                return event.target.className !== 'tool-tip'
            },
            limitArray(arr) {
                const arr2 = [];

                for (const key in arr) {
                    const cur = arr[key];

                    arr2[key] = {
                        ...cur
                    };
                }

                return arr;
            },
            getTableId() {
                return this.id;
            },
            orderRowData(stratType) {
                const obj = this.strat ? stratType[this.strat] : stratType;
                const arr = [];
                for (const key in obj) {
                    const cur = obj[key];
                    const order = cur.StratOrder || cur.Total.StratOrder;
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },

            orderRowDataStateLocal(stratType) {
                const obj = this.strat ? (this.strat === "Total" ? stratType[this.strat].Total : stratType[this.strat]) : stratType;
                const arr = [];
                for (const key in obj) {
                    const cur = this.strat !== "Total" ? obj[key].Total : obj[key];
                    const order = this.strat !== "Total" ? cur.StratOrder2 : cur.StratOrder
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },
            orderRowDataMedian(stratType) {
                let obj = (this.strat === "Total" || this.strat === "") ? stratType.Sex : stratType[this.strat];
                const arr = [];
                for (const key in obj) {
                    const cur = obj[key];
                    const order = cur.StratOrder;
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },
            getLocationCode(locationName) {
                return locationDictionary.byName()[locationName];
            },
            getLinkToGraph(locationName) {
                const { questionCode, topicCode, year } = this.$route.query;
                const locationCode = this.getLocationCode(locationName);
                return `/graphs?questionCode=${questionCode}&topicCode=${topicCode}&year=${year}&location=${locationCode}`;
            },
            getYouthOnlineLink(locationName) {
                const { questionCode, year } = this.$route.query;
                const locationCode = this.getLocationCode(locationName);
                const columnVariable = this.strat;
                const nativeDirection = this.$store.state.ui.NativeDirection;
                const args = {
                    questionCode,
                    locationId: locationCode,
                    year,
                    columnVariable,
                    nativeDirection
                };
                return createLinkToYOL(args);
            },
            tableCloseTooltip() {
                if (this.tipData) {
                    if (!event.target.classList.contains('tool-tip')) {
                        this.closeToolTip();
                    }
                }
            },
            closeToolTip() {
                this.tipData = null;
            },
            showToolTip(stratName) {
                let x;
                let y;
                let keyboard = false;
                const rect = document
                    .getElementById('table-wrapper')
                    .getBoundingClientRect();
                if (event.type === 'keyup') {
                    const {  top} = event.target.getBoundingClientRect();
                    x = rect.left - 120
                    y = top - rect.top + 260;
                    keyboard = true;
                } else {
                    const { layerX, layerY } = event;
                    x = layerX
                    y = layerY
                }

                this.tipData = {
                    x,
                    y,
                    graphLink: this.getLinkToGraph(stratName),
                    youthOnlineLink: this.getYouthOnlineLink(stratName),
                    keyboard,
                    locationCode: locationDictionary.byName()[stratName]
                };
            },
            graphLink() {
                const { locationCode, graphLink } = this.tipData;
                analytics.selectViewGraph({
                    ...this.$route.query,
                    locationCode,
                    strat: this.$parent.strat
                });
                this.$router.push(graphLink);
            },
            yolLink() {
                const { locationCode, youthOnlineLink } = this.tipData;
                analytics.selectViewInYOL({
                    ...this.$route.query,
                    locationCode,
                    strat: this.$parent.strat
                });
                window.open(youthOnlineLink, '_blank');
            }
        }
    };
</script>

<style lang="scss">
    @import '../../../scss/variables';
    $lborder: 1px solid black;
    $bborder: 4px solid black;




 //  #national-Total thead {
 //      tr:nth-child(2) {
 //          th:nth-child(1) {
 //              border-left: 0px;
 //              border-top: 0px;
 //          }
 //      }
 //  }
 //  
 //  #national-Total tbody tr:first-child {
 //      @media only screen and (min-width: 600px) {
 //          th, td {
 //              font-size: 20px;
 //          }
 //      }
 //
 //      th:nth-child(1), td:nth-child(2), td:nth-child(4), td:nth-child(6) {
 //          font-weight: bold;
 //      }
 //  }

    //thead tr:nth-child(2) {
    //    th:first-child {
    //        font-weight: bold;
    //    }
    //}

    </style>
