import { doInteraction, doAnalytics } from '../../utils/analytics';

export function pageLoaded() {
    doAnalytics({
        levels: ['cdc', 'dash', 'yrbs'],
        channel: 'YRBS Explorer'
    });
}

// H stands for Home

// S: Search
export function searchEnter({ searchTerm }) {
    // User selects Search Button
    doInteraction('YRBS|H|S|' + searchTerm);
}

// @TODO add search clear functionality;
// export function searchClear() {
//   // User clears Search
//   doInteraction('YRBS|H|S|clear');
// }

// F: Filter
// @TODO Ask UI why we are doing abbreviations instead of using the Topic Code.
export function selectFilter({ topicCode }) {
    // User selects a filter
    doInteraction('YRBS|H|F|' + topicCode);
}

export function selectSeeAll() {
    // User selects "See All Topics"
    doInteraction('YRBS|H|F|all');
}

// T: Tiles
export function selectTile({ questionCodeAndTerm }) {
    // User selects the tile (topics icon or question test on the tile)
    doInteraction('YRBS|H|T|' + questionCodeAndTerm);
}

export function selectGraphs({ questionCode }) {
    // user selects "Graphs" link
    doInteraction('YRBS|H|TG|' + questionCode);
}

export function selectTables({ questionCode }) {
    // user selects "Tables" link
    doInteraction('YRBS|H|TT|' + questionCode);
}

// N: Navigation
export function selectPageTitle() {
    // User selects "Youth Risk Behavior Survey Results" page title
    doInteraction('YRBS|H|N|Title');
}

export function selectHelp() {
    // User selects "help"
    doInteraction('YRBS|H|N|Help');
}

export function selectScrollToTop() {
    // User scrolls to top
    doInteraction('YRBS|H|N|STT');
}

export function selectIntro() {
    // User selects "Introduction" link
    doInteraction('YRBS|H|Intro');
}
