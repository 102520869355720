 /* eslint-disable */
const dictionary = {
  C01: {
    component: 'injury',
    text: 'Unintentional Injuries and Violence', //<i class="fas fa-ambulance"></i>
    color: '#00788A'
  },
  C02: {
    component: 'tobacco',
    text: 'Tobacco Use', // <i class="fas fa-smoking"></i>
    color: '#665546'
  },
  C03: {
    component: 'drugs',
    text: 'Alcohol and Other Drug Use', // <i class="fas fa-pills"></i>
    color: '#8A1E04'
  },
  C04: {
    component: 'sex',
    text: 'Sexual Behaviors', // <i class="fas fa-smile-wink"></i> (though there has also been discussions about <i class="fas fa-heart"></i>)
    color: '#005DAB'
  },
  C05: {
    component: 'dietary',
    text: 'Dietary Behaviors', // <i class="fas fa-utensils"></i>
    color: '#578222'
  },
  C06: {
    component: 'fitness',
    text: 'Physical Activity', // <i class="fas fa-running"></i>
    color: '#9A4C9E'
  },
  C07: {
    component: 'obesity',
    text: 'Obesity, Overweight and Weight Control', // <i class="fas fa-weight"></i>
    color: '#594099'
  },
  C08: {
    component: 'other',
    text: 'Other Health Topics', // <i class="fas fa-medkit"></i>
    color: '#BC5B06'
  }
};

export default dictionary;