<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Unintentional Injuries and Violence
    </title>
    <path
      :fill="color"
      d="M39.35,27.86V21.41l-4-2.53-3-6.62a2.74,2.74,0,0,0-2.29-1.49H30v-1a1.35,1.35,0,0,0-2.7,0v1H25.91V8.17H1.55V25.36H.5v2.06H3.39l1.28,2h.38a5,5,0,0,0,9.87,0H28.24a5,5,0,0,0,9.87,0H39.5V27.86ZM10,31.38a2.53,2.53,0,1,1,2.53-2.53A2.54,2.54,0,0,1,10,31.38Zm7.78-13.19H15.35v2.42H12.5V18.19H10.08V15.34H12.5V12.92h2.85v2.42h2.42Zm8.14-.12V12.4h4.21a1.19,1.19,0,0,1,.81.53l2.32,5.14Zm7.27,13.31a2.53,2.53,0,1,1,2.53-2.53A2.54,2.54,0,0,1,33.18,31.38Z"
    /></svg
></template>
<script>
export default {
  props: ['color']
};
</script>
