<template>
    <div class="loader__component" role="status" :style="{ height: height ? height + 'px' : '' }">
        <div class="spinner-border text-primary">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['height']
    }
</script>

<style lang="scss">
    .loader__component {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 200000;
    }
</style>