<template>
  <!-- <div class="overflow-x__gradient"> -->
  <div :class="[!isIE ? 'overflow-x' : '']">
    <slot></slot>
  </div>
  <!-- </div> -->
</template>

<script>
import { isInternetExplorer } from '../../utils/helpers';
export default {
  computed: {
    isIE() {
      return isInternetExplorer();
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/variables';
.overflow-x {
  // position: relative;
  overflow-x: scroll;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray;
    border-radius: 10px;
  }
  // &__gradient {
  //   position: relative;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     height: 100%;
  //     width: 10px;
  //     background: linear-gradient(90deg, $graylightest, rgba(255, 255, 255, 0));
  //     z-index: 2;
  //   }
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     height: 100%;
  //     width: 10px;
  //     background: linear-gradient(90deg, rgba(255, 255, 255, 0), $graylightest);
  //   }
  // }
}
</style>
