<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Tobacco Use
    </title>
    <path
      :fill="color"
      d="M37.41,34.14V27.91H5.88v6.23ZM24.35,31V29.27L32.92,31H27.43v1.75L18.86,31Zm-10.56-.75A.75.75,0,1,1,13,31,.75.75,0,0,1,13.79,30.27Zm-2.78,0a.75.75,0,1,1,0,1.5.75.75,0,0,1,0-1.5Zm-2.79,0a.75.75,0,1,1,0,1.5.75.75,0,0,1,0-1.5Z"
    />
    <rect
      x="0.5"
      y="28.9"
      width="4.55"
      height="4.24"
      :fill="color"
      transform="translate(5.55 62.05) rotate(-180)"
    />
    <rect :fill="color" x="38.25" y="27.91" width="1.25" height="6.23" />
    <rect :fill="color" x="11.62" y="15.58" width="23.18" height="5.56" />
    <rect :fill="color" x="35.79" y="15.58" width="1.45" height="5.56" />
    <rect :fill="color" x="38.03" y="15.58" width="1.47" height="5.56" />
    <rect :fill="color" x="0.5" y="15.58" width="10.14" height="5.56" />
    <path
      :fill="color"
      d="M37.25,14.3S39.09,8.62,34.72,7s-3.41-5.1-3.41-5.1-2,4.8,2.39,7.22S37.25,14.3,37.25,14.3Z"
    /></svg
></template>

<script>
export default {
  props: ['color']
};
</script>
