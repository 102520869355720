<template>
  <div class="container bg__white charts__wrapper">
    <StickyHeader
      v-if="topicCode"
      :topicCode="topicCode"
      page="Graph"
      :jumpTo="[
        {
          text: 'Patterns Over Time',
          cb: () => {
            scrollTo('lineChart');
          },
          tab: () => {
            scrollTo('lineChart', true);
          },
          show: yearOptions.length > 1
        },
        {
          text: 'Details From a Specific Year',
          cb: () => {
            scrollTo('barChart');
          },
          tab: () => {
            scrollTo('barChart', true);
          },
          show: !!chartData.length
        }
      ]"
    >
      <NestedDropdown :onchange="changeLocation" :selected="selectedLocation" label="Location" :showAllLocationItem="false"/>
      <Dropdown
        v-if="
          (isIE && yearOptions.length > 1) ||
            (addYear && yearOptions.length > 1)
        "
        :options="yearOptions"
        :onchange="changeYear"
        :selected="selectedYear"
        label="Details From a Specific Year"
      />
      <p
        v-if="yearOptions.length <= 1 && yearOptions[0]"
        :class="[
          'charts__data-for-year',
          shouldPrint('bar'),
          'sticky__dropdown'
        ]"
      >{{ 'Data for ' + yearOptions[0] }}</p>
    </StickyHeader>
    <!--ERROR MESSAGE-->
    <div v-if="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
    <p class="d-none d-print-inline">Location: {{ currentLocation }}</p>
    <LinksYOLChartTable
      class="getlink"
      v-if="yearOptions.length > 1"
      :queryParams="{ ...queryParams, year: null }"
      :showShareLink="true"
    />
    <div v-if="!lineGraphLoader" id="lineChart" tabindex="-1">
        <div :class="['charts__title', shouldPrint('trend')]" v-if="yearOptions.length > 1">
            <h4 :style="{ color }">
                Patterns Over Time
            </h4>
            <p v-html="trendGraphText" v-show="showingComparison == false"></p>
            <p v-html="trendGraphComparisonText" v-show="showingComparison == true"></p>
        </div>

        <div :class="['row justify-content-end text-end compare-to-national']" v-if="selectedLocation !== 'XX' && yearOptions.length > 1">

            <div class="col-12">
                <svg id="local-legend-item" height=25 width="200" :class="[shouldPrint('trend')]"></svg><svg id="national-legend-item" height=25 width="200" :class="[shouldPrint('trend')]"></svg>
                <button v-if="selectedLocation !== 'XX' && showingComparison == false" :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]" @click="toggleComparison(true)">
                    Compare to United States
                </button>
                <button v-if="selectedLocation !== 'XX' && showingComparison == true" :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]" @click="toggleComparison(false)">
                    Hide Comparison
                </button>
            </div>
        </div>
        <div :class="[shouldPrint('trend')]" v-if="yearOptions.length > 1">
                <Observer @onScreen="addYear = true" @offScreen="addYear = false" />
                <LineGraph id="linegraph1"
                           :chartData="getByStrat('Total')"
                           :location="currentLocation"
                           :color="color"
                           :key="selectedLocation"
                           v-if="showingComparison == false" />

                <LineGraphComparison id="linegraph2"
                                     v-if="showingComparison == true"
                                     :chartData="getByStrat('Total')"
                                     :chartData2="getByStrat2('Total')"
                                     :location="currentLocation"
                                     :color="color"
                                     :key="selectedLocation" />

                <Footnotes :class="shouldPrint('trend')"
                           :footnotes="trendFootNotes"
                           :missingData="missingTrend"
                           :filterCB="chartFilter" />
        </div>
        
        <button @click="exportToPDF('trend')"
                :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]"
                v-if="yearOptions.length > 1">
            Print/Save to PDF
        </button>
        <button :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]"
                v-if="yearOptions.length > 1"
                @click="
          csvExport(getByStrat('Total'), selectedLocation, trendFootNotes,'Trend', showingComparison == true ? getByStrat2('Total'):null)
        ">
            Export to CSV
        </button>
        <hr />
        <!--BarChart Title-->
        <div id="barChart"
             tabindex="-1"
             class="charts__title label-and-links"
             v-if="chartData.length">
            <p :style="{ color }" :class="['header', shouldPrint('bar')]">Details From a Specific Year</p>
            <span :class="['d-none', shouldPrint('bar', 'd-print-inline')]">Year: {{ selectedYear }}</span>
            <LinksYOLChartTable type="table"
                                :queryParams="queryParams"
                                :showShareLink="yearOptions.length === 1" />
        </div>
        <div :class="['charts__title', shouldPrint('bar')]">
            <p v-if="chartData.length">
                We measure the prevalence of {{ shortQuestionText }} by
                {{ stratTypesList }}.<br /> Here's how high school students responded in
                <b>{{ selectedYear }}</b>.
            </p>
        </div>
        <!-- removing :key from BarChart will break reactivity because of d3 -->
        <div class="confidence-toggle-container d-print-none">
            <button v-if="chartData.length"
                    @click="confidenceToggle(false)"
                    :class="['btn mr-2 d-print-none', `yrbs-cat__color--${this.showCIOnBarGraph ? 'none' : topicCode}`, `confidence-left-button`] ">
                Percent
            </button>
            <button v-if="chartData.length"
                    :class="['btn mr-2 d-print-none',`yrbs-cat__color--${this.showCIOnBarGraph ? topicCode : 'none'}`, `confidence-right-button`]"
                    @click="confidenceToggle(true)">
                Confidence Interval
            </button>
        </div>
        <BarChart v-if="chartData.length"
                  :chartData="chartData"
                  :class="shouldPrint('bar')"
                  :color="color"
                  :location="currentLocation"
                  :selectedYear="selectedYear"
                  :stratTypesList="stratTypesList"
                  :key="selectedLocation + selectedYear"
                  :showCIOnBarGraph="showCIOnBarGraph"
                  :barChartId="barChartId" />
        <Footnotes v-if="chartData.length"
                   :class="shouldPrint('bar')"
                   :footnotes="barFootNotes"
                   :filterCB="chartFilter"
                   :missingData="missingBar" />
        <button v-if="chartData.length"
                @click="exportToPDF('bar')"
                :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]">
            Print/Save to PDF
        </button>
        <button v-if="chartData.length"
                :class="['btn mr-2 d-print-none', `yrbs-cat__color--${topicCode}`]"
                @click="
                csvExport(
                chartData.filter(d=>
            d.Year === selectedYear),
            selectedLocation,
            barFootNotes,
            'Bar'
            )
            "
            >Export to CSV
        </button>
        <p class="col pt-3">
            <b>Recommended Citation:</b> Centers for Disease Control and Prevention (CDC). 1991-2023 High School Youth Risk Behavior Survey Data.
            Available at
            <a href="http://yrbs-explorer.services.cdc.gov/">http://yrbs-explorer.services.cdc.gov/</a>. Accessed on [date].
        </p>
    </div>
    <Loader v-else style="top: 0; left: 0; right: 0; bottom: 0;" />
    <ScrollToTop scrollTo=".charts__wrapper" />
  </div>
</template>

<script>
    import LineGraph from './LineGraph';
    import LineGraphComparison from './LineGraphComparison';
    import BarChart from './BarChart';
    import Loader from '../modules/Loader.vue';
  //  import OverFlowX from '../modules/OverFlowX.vue';
    import StickyHeader from '../modules/StickyHeader.vue';
    import Observer from '../modules/Observer.vue';
    import LinksYOLChartTable from '../modules/LinksYOLChartTable';
    import * as api from '../../utils/api';
    import { insertQueryParam, isInternetExplorer } from '../../utils/helpers';
    import {
        getStratTypesString,
        formatChartData,
        hasMissingData,
        getAvailableYear,
    } from './helpers';
    import Dropdown from '../modules/Dropdown';
    import * as analytics from './analyticsHelpers';
    import NestedDropdown from '../modules/NestedDropdown/index.js';
    import locationDictionary from '../../utils/locationDictionary';
    import tileDictionary from '../../utils/tileDictionary';
    import Footnotes, { chartFilter } from '../modules/Footnotes';
    import ScrollToTop from '../modules/ScrollToTop.vue';
    import VueScrollTo from 'vue-scrollto';
    import { csvFootnotes, saveCSV } from '../../utils/csvExportHelpers';

    export default {
        components: {
            LineGraph,
            LineGraphComparison,
            BarChart,
            Loader,
            //OverFlowX,
            StickyHeader,
            Dropdown,
            Footnotes,
            NestedDropdown,
            LinksYOLChartTable,
            Observer,
            ScrollToTop,
        },
        data() {
            return {
                chartData: [],
                chartData2: {},
                color: null,
                yearOptions: [],
                selectedYear: '',
                selectedLocation: this.$route.query.location || 'XX',
                lineGraphLoader: true,
                errorMessage: null,
                conversationalText: '',
                addYear: false,
                toPrint: '',
                trendDaggarNote: false,
                trendFootNotes: [],
                barFootNotes: [],
                missingData: {},
                showCIOnBarGraph: false,
                barChartId: 'mybarchart_1',
                showingComparison: false,
            };
        },
        updated() {
            const { selectedLocation, selectedYear } = this;
            if (selectedLocation) {
                insertQueryParam('location', selectedLocation);
            }
            if (selectedYear) {
                insertQueryParam('year', this.selectedYear);
            }
        },
        created() {
            window.scrollTo(0, 0);
        },
        mounted() {
            this.$store
                .dispatch('ui/getQuestion', this.$route.query.questionCode)
                .then(() => {
                    this.refreshChartData(this.selectedLocation);
                });
            const logo = document.querySelector('.cdc-logo a');
            if (logo) logo.focus();
        },
        computed: {
            getLocation() {
                return locationDictionary.byCodeFlat()[this.selectedLocation];
            },
            trendGraphText() {
                let _this = this;

                const newText = this.boldText(this.conversationalText)
                const hasFootNote = this.trendFootNotes.filter(item => item.symbol === '†');

                let sentenceArray = newText.split(".");
                sentenceArray = sentenceArray.filter(item => item.trim() != '');
                sentenceArray.forEach(myFunction);

                

                function myFunction(item, index) {
                    let newItem = item;
                    if (newItem == '') return;
                    if (index + 1 == sentenceArray.length) {
                        newItem = item + '.' + (hasFootNote.length > 0 ? '<sup>†</sup>' : '') + '<br/>';
                    }
                    else {
                        newItem = item + '.<br/>';
                    }
                    const newlocation = _this.getLocation === 'United States' ? ' The ' + _this.getLocation : _this.getLocation;
                    if (newItem.indexOf("Here's what's been happening.") > -1) newItem = newItem.replace('.', ' in ' + newlocation + '.')
                    sentenceArray[index] = newItem;
                }

                let newConversationalText = sentenceArray.join('')

                return newConversationalText;
            },
            trendGraphComparisonText() {
                let _this = this;
                if (!this.chartData2 || !this.chartData2.conversationalText) return "";

                const newText = this.boldText(this.conversationalText)
                const hasFootNote = this.trendFootNotes.filter(item => item.symbol === '†');
                const newText2 = this.boldText(this.chartData2.conversationalText)

                //build first sentence array
                let sentenceArray = newText.split(".").filter(item => item.trim() != '');
                sentenceArray.forEach(myFunction);

                

                function myFunction(item, index) {
                    let newItem = item;
                    if (newItem == '') return;
                    if (index + 1 == sentenceArray.length) {
                        newItem = item + '.' + (hasFootNote.length > 0 ? '<sup>†</sup>':'') + '<br/>';
                    }
                    else {
                        newItem = item + '.<br/>';
                    }
                    if (newItem.indexOf("Here's what's been happening.") > -1) newItem = newItem.replace('.', ' in ' + _this.getLocation + '.')

                    sentenceArray[index] = newItem;
                }

                //build second sentence array(national)
                let sentenceArray2 = newText2.split(".").filter(item => item.trim() != '');
                sentenceArray2.forEach(myFunction2);

                function myFunction2(item, index) {
                    let newItem = item;
                    if (newItem == '') return;
                    if (index + 1 == sentenceArray.length) {
                        newItem = item.replace('We', 'The United States') + '.<sup>††</sup><br/>';
                    }
                    else {
                        newItem = item.replace('We', 'The United States') + '.<br/>';
                    }
                    if (newItem.indexOf("Here's what's been happening.") > -1) newItem = newItem.replace('.', ' in The United States.')
                    sentenceArray2[index] = newItem;
                }
                //replace 'We' with location
                sentenceArray[1] = sentenceArray[1].replace('We', this.getLocation);

                //add index 1 to main array. Do some cleanup... Replace 'We' with United States. Add subscript icon
                sentenceArray.push(sentenceArray2[1])

                return sentenceArray.join('');
            },
            barGraphText() {
                const newText = this.boldText(this.conversationalText)

                let sentenceArray = newText.split(".");
                sentenceArray = sentenceArray.filter(entry => entry.trim() != '');
                sentenceArray.forEach(myFunction);

                function myFunction(item, index) {
                    let newItem = item;
                    if (newItem == '') return;
                    if (index + 1 == sentenceArray.length) {
                        newItem = item + '.<sup>†</sup>';
                    }
                    else {
                        newItem = item + '.<br/>';
                    }
                    sentenceArray[index] = newItem;
                }

                //sentenceArray[sentenceArray.length - 1] = sentenceArray[sentenceArray.length - 1] + '†';
                let newConversationalText = sentenceArray.join('')

                return newConversationalText;
            },
            isIE() {
                return isInternetExplorer();
            },
            topicCode() {
                return this.$route.query.topicCode;
            },
            shortQuestionText() {
                return this.$store.state.ui.ShortQuestionText.toLowerCase();
            },
            topicText() {
                return tileDictionary[this.topicCode].text;
            },
            queryParams() {
                return { location: this.selectedLocation, year: this.selectedYear };
            },
            stratTypesList() {
                return getStratTypesString(this.chartData, this.selectedYear);
            },
            currentLocation() {
                const loc = locationDictionary.byCodeFlat()[this.selectedLocation];
                return loc;
            },
            missingTrend() {
                const sorted = [...this.yearOptions].sort();
                const minYear = +sorted[0];
                const maxYear = +sorted[sorted.length - 1];
                const expectedYearsBetween = (sorted.length - 1) * 2;
                return maxYear - (minYear + expectedYearsBetween) !== 0;
            },
            missingBar() {
                const { missingData, selectedYear } = this;
                return this.hasMissingData({ missingData, selectedYear });
            },
        },
        methods: {
            boldText(text) {
                return text
                    .replace('decrease', '<b>' + 'decrease' + '</b>')
                    .replace('increase', '<b>' + 'increase' + '</b>');
            },
            toggleComparison(showingComparison) {
                this.showingComparison = showingComparison;

                const { questionCode, location } = this.$route.query;

                if (this.showingComparison) {
                    this.lineGraphLoader = true;
                    this.refreshChartData('XX', true);
                    analytics.compareLocation({ locationCode: location, questionCode: questionCode });
                } else {
                    this.chartData2 = [];
                    this.lineGraphLoader = true;
                    this.refreshChartData(this.selectedLocation, false);
                    analytics.removeCompare({ locationCode: location, questionCode: questionCode });
                }
            },
            shouldPrint(printArea, className = '') {
                return printArea !== this.toPrint ? 'd-print-none' : className;
            },
            exportToPDF(printArea) {
                this.toPrint = printArea;
                const { year, questionCode } = this.$route.query;
                const locationCode = this.selectedLocation;
                if (printArea === 'bar')
                    analytics.barPDFExport({ questionCode, locationCode });
                if (printArea === 'trend')
                    analytics.trendPDFExport({ questionCode, locationCode, year });
                this.$nextTick(() => {
                    window.print();
                });
            },
            getByStrat(strat) {
                return this.chartData.filter((data) => data.Strat === strat);
            },
            getByStrat2(strat) {
                return this.chartData2.chartData.filter((data) => data.Strat === strat);
            },
            changeLocation(location) {
                const { questionCode, year } = this.$route.query;
                analytics.selectLocation({ locationCode: location, questionCode, year });
                this.lineGraphLoader = true; // set the loader
                if (location === 'XX') this.showingComparison = false;
                this.refreshChartData(location);

                this.showingComparison = false;
            },
            removeLoader() {
                this.lineGraphLoader = false;
            },
            scrollTo(location, tab = false) {
                analytics.selectOnThisPage({
                    scrollToLocation: location,
                    ...this.$route.query,
                    locationCode: this.selectedLocation,
                });
                if (tab) {
                    document.getElementById(location).focus();
                }
                VueScrollTo.scrollTo(`#${location}`, 500, { offset: -200 });
            },
            changeYear(year) {
                if (this.isIE) {
                    this.scrollTo('barChart');
                }
                const yr = getAvailableYear(year, this.yearOptions);
                const questionCode = this.$route.query.questionCode;
                const locationCode = this.selectedLocation;
                analytics.selectYear({ locationCode, questionCode, year: yr });
                this.selectedYear = yr;
            },
            refreshChartData(newLocation, isCompare) {
                const _this = this;
                const { questionCode, topicCode, year } = this.$route.query;
                const args = { questionId: questionCode, locationId: newLocation };
                const shortQuestion = this.$store.state.ui.ShortQuestionText;
                const chartPromise = api.getChartData(args);
                const footnotePromise = api.getChartFootNotes(args);
                // Promise is being returned only for testing purposes
                return Promise.all([footnotePromise, chartPromise])
                    .then(([footnoteData, chartData]) => {
                        Object.assign(_this, {
                            ...formatChartData({
                                footnoteData,
                                chartData,
                                topicCode,
                                year,
                                shortQuestion,
                                isCompare,
                            }),
                            ...(!isCompare) && { selectedLocation: newLocation },
                        });

                        if (isCompare) {
                            let newitem = this.chartData2.trendFootNotes[2];
                            newitem.symbol = newitem.symbol + newitem.symbol;
                            this.trendFootNotes.splice(3, 0, newitem);
                        }

                        _this.removeLoader();
                    })
                    .catch((error) => {
                        _this.errorMessage = error.message || error.status;
                        _this.lineGraphLoader = false;
                        console.error(error.message || error.status);
                    });
            },
            csvExport(chartData, location, footnotes, type = 'Trend', chartData2 = null) {
                const [Footnote1, Footnote2, Footnote3, Footnote4] = csvFootnotes(
                    footnotes,
                    /Missing (data point|bars) indicate/
                );
                const { topicCode, questionCode } = this.$route.query;
                const { Question } = this.$store.state.ui;
                if (type === 'Trend')
                    analytics.trendCSVExport({ questionCode, locationCode: location });
                if (type === 'Bar')
                    analytics.barCSVExport({
                        questionCode,
                        locationCode: location,
                        year: this.selectedYear,
                    });
                let $data = chartData.map((d) => {
                    return {
                        Year: d.Year,
                        LocationDesc: locationDictionary.byCodeFlat()[location],
                        DataSource: 'YRBS',
                        Topic: this.topicText,
                        Question,
                        StratificationCategory: d.StratType,
                        Stratification: d.Strat,
                        DataValueUnit: '%',
                        DataValue: d.MainValue,
                        LowCI: d.LowCI,
                        HighCI: d.HighCI,
                        Footnote1,
                        Footnote2: d.MainValue ? '' : Footnote2,
                        Footnote3,
                        Footnote4,
                        TopicID: topicCode,
                        QuestionID: questionCode,
                    };
                });

                if (chartData2) {
                    const $data2 = chartData2.map((d) => {
                        return {
                            Year: d.Year,
                            LocationDesc: locationDictionary.byCodeFlat()['XX'],
                            DataSource: 'YRBS',
                            Topic: this.topicText,
                            Question,
                            StratificationCategory: d.StratType,
                            Stratification: d.Strat,
                            DataValueUnit: '%',
                            DataValue: d.MainValue,
                            Footnote1,
                            Footnote2: d.MainValue ? '' : Footnote2,
                            Footnote3,
                            Footnote4,
                            TopicID: topicCode,
                            QuestionID: questionCode,
                        };
                    });
                    $data = [...$data, ...$data2];
                }

                saveCSV($data, `YRBS_${type}_Chart.csv`);
            },
            hasMissingData,
            chartFilter,
            confidenceToggle(showCIOnBarGraph) {
                this.showCIOnBarGraph = showCIOnBarGraph;

                const { questionCode, location, year } = this.$route.query;
                if (showCIOnBarGraph) {
                    analytics.ciToggle({ questionCode: questionCode, locationCode: location, year: year });
                }
                else {
                    analytics.pctToggle({ questionCode: questionCode, locationCode: location, year: year });
                }
                

            }
        },

    };
</script>

<style lang="scss">
    @import '../../scss/_variables';

    .charts {
        &__wrapper {
            min-height: 1000px;
            position: relative;

            .getlink {
                margin-top: 10px;
                float: right;
            }
        }

        &__data-for-year {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            margin-top: 40px;

            h4 {
                font-family: 'Open Sans';
                margin: 0;
                width: 100%;
            }
        }
    }

    .label-and-links {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: flex-end;

        & > p {
            margin-bottom: 0;
        }
    }

    .daggerFont {
        font-family: sans-serif;
    }

    sup {
        font-size: x-large;
        line-height: 1px;
    }

    .confidence-toggle-container {
        text-align: left;
        margin-left: 30%;
    }

    .confidence-left-button {
        border-radius: 6.735px 0 0 6.735px;
        border-right: 0px solid black !important;
    }

    .confidence-right-button {
        border-radius: 0 6.735px 6.735px 0;
        border-left: 0px solid black !important;
    }

    g line {
        margin-bottom: 100px
    }

    .compare-to-national {
        text-align: right;
    }
    </style>
