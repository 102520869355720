<template>
  <div class="dropdown__wrapper">
    <label :id="labelId">{{ label }}&nbsp;</label>
    <select @change="handleChange" class="dropdown__select" :aria-labelledby="labelId">
      <optgroup v-if="grouped" v-for="($options, group) in options" :key="group" :label="group">
        <option
          v-for="(value, $key) in $options"
          :key="value"
          :selected="
            Array.isArray(options) ? value === selected : $key === selected
          "
          :value="Array.isArray(options) ? value : $key"
        >
          <!-- if options is an object, use the key as the select value -->
          {{ value }}
        </option>
      </optgroup>
      <option
        v-if="!grouped"
        v-for="(value, $key) in options"
        :key="value"
        :selected="
          Array.isArray(options) ? value === selected : $key === selected
        "
        :value="Array.isArray(options) ? value : $key"
      >
        <!-- if options is an object, use the key as the select value -->
        {{ value }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['options', 'onchange', 'selected', 'label', 'grouped'],
  computed: {
    labelId() {
      return this.label.replace(' ', '-') + '-dropdown';
    }
  },
  methods: {
    handleChange(e) {
      const { selectedIndex, options } = e.target;
      const { value } = options[selectedIndex];
      this.onchange(value);
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/variables';
.dropdown {
  &__wrapper {
    display: flex;
    align-items: end;
    justify-content:end;
    width: 100%;
    & > label {
      padding: 7px 15px;
      margin: 0;
    }
  }
  &__select {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid $gray;
    border-radius: 0.25rem;
  }
}
</style>
