import { createStore} from 'vuex'
import questions from './modules/questions';
import ui from './modules/ui';
import createLogger from '../utils/logger';
import tables from './modules/tables';

// const debug = process.env.NODE_ENV !== 'production';
const debug = false;

export default createStore({
    modules: {
        questions,
        ui,
        tables
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});


