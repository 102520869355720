function getRandomCryptoValue() {
    // this function is used because Fortify SCA doesn't like Math.random()
    var crypto = window.crypto || window.msCrypto; // handles Internet Explorer
    return crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295;
}

export function shuffle(sourceArray) {
    //https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(getRandomCryptoValue() * (sourceArray.length - i));

        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
}

export function isMobile() {
    const isMobile = window.outerWidth <= 767;
    return isMobile;
}

export function isInternetExplorer() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
        // If Internet Explorer, return version number
        return true;
    }
    if (/Edge\/\d./i.test(navigator.userAgent)) {
        // The above should match Older versions of Edge before Chromium.
        return true;
    }
    return false;
}

export function showFullscreenLoader(showLoader) {
    const loader = document.getElementById('fullscreenLoader');
    if (loader) loader.style.display = showLoader ? 'flex' : 'none';
}

//function to remove query params from a URL
function removeURLParameter(url, parameter) {
    //better to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';
        const params = urlparts[1].split(/[&;]/g);
        //reverse iteration as may be destructive
        for (let i = params.length; i-- > 0;) {
            //idiom for string.startsWith
            if (params[i].lastIndexOf(prefix, 0) !== -1) {
                params.splice(i, 1);
            }
        }
        url = urlparts[0] + (params.length > 0 ? '?' + params.join('&') : '');
    }
    return url;
}

export function insertQueryParam(key, value) {
    if (history.pushState) {
        // var newurl = window.location.protocol + "//" + window.location.host + search.pathname + '?myNewUrlQuery=1';
        const url = window.location.href;
        //remove any param for the same key
        const cleanUrl = removeURLParameter(url, key);
        //figure out if we need to add the param with a ? or a &
        const queryStart = cleanUrl.indexOf('?') !== -1 ? '&' : '?';
        const newurl = cleanUrl + queryStart + key + '=' + value;
        window.history.replaceState({}, '', newurl);
    }
}

export function containsInNestedObject(obj, val) {
    if (obj === val) {
        return true;
    }
    const keys = obj instanceof Object ? Object.keys(obj) : [];
    for (const key of keys) {
        const objval = obj[key];
        const isMatch = containsInNestedObject(objval, val);
        if (isMatch) {
            return true;
        }
    }
    return false;
}

export function createLinkToYOL({
    questionCode,
    locationId,
    year,
    columnVariable,
    nativeDirection
}) {

    const columnIdMemo = {
        total: 'S',
        sex: 'S',
        race: 'R',
        'sexual identity': 'I',
        'sex of sexual contacts': 'P',
        grade: 'G'
    };
    const COL = columnIdMemo[columnVariable.toLowerCase()];
    const QP = nativeDirection !== 'Negative' ? 'L' : 'G';
    // /youthonline/ is the redirected by the nginx server to nccd. nccd will change to (nccd|nccddev|nccdqa.cdc.gov). See nginx folder
    return `/youthonline/Youthonline/App/Results.aspx?SID=HS&QID=${questionCode}&LID=${locationId}&YID=${year}&COL=${COL}&QP=${QP}`;
}

export const scroll = {
    // Referenced from https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    keys: { 37: 1, 38: 1, 39: 1, 40: 1 },
    preventDefault(e) {
        e.preventDefault();
    },
    preventDefaultForScrollKeys(e) {
        if (this.keys[e.keyCode]) {
            this.preventDefault();
            return false;
        }
    },
    // modern Chrome requires { passive: false } when adding event
    getWheelOpt() {
        // modern Chrome requires { passive: false } when adding event
        let supportsPassive = false;
        try {
            window.addEventListener(
                'test',
                null,
                Object.defineProperty({}, 'passive', {
                    get() {
                        supportsPassive = true;
                        return null;
                    }
                })
            );
            window.removeEventListener('test', null);
        } catch (e) {
            return null;
        }
        return supportsPassive ? { passive: false } : false;
    },
    wheelEvent:
        'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel',
    // call this to Disable
    disable() {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(
            this.wheelEvent,
            this.preventDefault,
            this.getWheelOpt()
        ); // modern desktop
        window.addEventListener(
            'touchmove',
            this.preventDefault,
            this.getWheelOpt()
        ); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    },
    // call this to Enable
    enable() {
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(
            this.wheelEvent,
            this.preventDefault,
            this.getWheelOpt()
        );
        window.removeEventListener(
            'touchmove',
            this.preventDefault,
            this.getWheelOpt()
        );
        window.removeEventListener(
            'keydown',
            this.preventDefaultForScrollKeys,
            false
        );
    }
};

export function env(routeName) {
    const dev = {
        cdc: 'https://wwwdev.cdc.gov'
    };
    const prod = {
        cdc: 'https://www.cdc.gov'
    };

    if (window.location.href.match(/apps.ecpaas-dev|localhost/)) {
        return dev[routeName];
    } else {
        return prod[routeName];
    }
}
