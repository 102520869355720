<template>
    <div id="home-container" class="container bg__white container__wrapper">        
        <div v-if="mobile && showMobileFilters" class="row">
            <FilterTiles />
        </div>
        <div v-if="mobile"
             class="row justify-content-center container__button--filter">
            <button @click="setMobileFilters(true)"
                    type="button"
                    class="btn btn-secondary">
                Filter Questions
            </button>
        </div>
        <div class="row px-3">
            <FilterTiles v-if="!mobile" />
            <div class="container__tiles col-12 col-md-8 col-lg-9">
                <p>
                    Explore Youth Risk Behavior Survey Questions - United States{{
            year && ', ' + year
                    }}
                </p>
                <span>
                    High School Survey Results shown. Additional <b>locations</b> and
                    <b>years</b> are available by selecting a question below.
                </span>
                <hr />
                <MasonryTiles :topicCode="topicCode"
                              :searchTerm="searchTerm"
                              :year="year" />
            </div>
        </div>
        <ScrollToTop scrollTo="#home-container" />
    </div>
</template>

<script>
import MasonryTiles from './MasonryTiles.vue';
import FilterTiles from './FilterTiles.vue';
import * as analytics from './analyticsHelpers';
import ScrollToTop from '../modules/ScrollToTop.vue';
import { scroll, env } from '../../utils/helpers';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedTopics: null,
      showMobileFilters: false,
      showScrollToTop: false
    };
  },
  components: {
    MasonryTiles,
    FilterTiles,
    ScrollToTop
  },
  created() {
    this.$store.dispatch('questions/getTopics');
    this.$store.commit('ui/removeQuestion');
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.handleScroll);
  },
  updated() {
    const { year } = this.$store.state.questions;
    if (year) {
      const title = `YRBS Explorer (${year}) | CDC`;
      if (document.title !== title) {
        document.title = title;
        analytics.pageLoaded();
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    const { year } = this.$store.state.questions;
    document.title = `YRBS Explorer ${year ? '(' + year + ') ' : ' '}| CDC`;
    if (year) analytics.pageLoaded();
    const logo = document.querySelector('.cdc-logo a');
    if (logo) logo.focus();
  },
  computed: {
    mobile() {
      return this.$store.state.ui.isMobile;
    },
    year() {
      return this.$store.state.questions.year;
    },
    ...mapState({
      topicCode: state => state.ui.topicCode,
      searchTerm: state => state.ui.searchTerm
    })
  },
  methods: {
    setMobileFilters(shouldShow) {
      scroll.disable();
      this.showMobileFilters = shouldShow;
    },
    goToIntro() {
      const url = env('cdc') + '/healthyyouth/data/yrbs/2019_tables/index.htm';
      analytics.selectIntro();
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/breakpoints';
.container {
  &__wrapper {
    width: 100%;
    min-height: 300px;
    padding-top: 20px;
  }
  &__button {
    &--filter {
      margin: 20px 0;
    }
    &--scroll-to-top {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1000;
      margin: 0 -30px 20px;
    }
  }
  &__tiles {
    & > p {
      margin: 15px 0 10px;
      font-size: x-large;
    }
    @media screen and (max-width: $md) {
      padding: 0;
    }
  }
}
</style>
