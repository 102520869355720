export default function capitalize(string) {
  if (string.match(/^CI =/)) return string; // this is for an edge case with Confidence Interval
  // the regex in split matches any period followed by one or more spaces
  const sentences = string.split(/\.\s+/);
  const capitalized = sentences.map(sentence => {
    // @TODO remove this code if bug is closed regarding capitalization
    // the positive look behind regex is not supported in anything earlier than ES2018.
    //const reg = /(?<=\[).+?/; // matches character directly following a left bracket.
    // const letterMatch = sentence.match(reg);
    let secondHalf = sentence.substring(1);
    // if (letterMatch) {
    //   secondHalf = secondHalf.replace(reg, letterMatch[0]);
    // }
    return sentence.charAt(0).toUpperCase() + secondHalf;
  });
  return capitalized.join('. ');
}
