<template>
    <div>        
        <div :class="[!isMobile && 'line-graph__wrapper']"></div>
    </div>
</template>

<script>
    import { createLineGraph } from './d3.methods';
    import { getMinMaxYears, getMinMaxValues } from '../d3.helpers';

    export default {
        props: ['chartData', 'color', 'location', 'chartData2'],
        data() {
            const combined = [...this.chartData, ...this.chartData2];
            
            const yearRange = getMinMaxYears(combined);            
            const mainValueRange = getMinMaxValues(combined);            
            
            return {
                margin: {
                    top: 10,
                    right: 30,
                    bottom: 30,
                    left: 100
                },
                height: 350,
                width: this.calcWidth(yearRange),
                yearRange, 
                mainValueRange,                
            };
        },
        mounted() {
            createLineGraph(this);
        },
        computed: {
            isMobile() {
                return this.$store.state.ui.isMobile;
            },
            title() {
                const { Question } = this.$store.state.ui;
                const [min, max] = this.yearRange;
                return `Trend chart showing responses to high school students who ${Question.replace(
                    '*',
                    ''
                ).toLowerCase()} in ${this.location} between ${min} and ${max}`;
            },
            description() {
                let desc = 'Trend chart shows response';
                const lastIndex = this.chartData.length - 1;
                function fmt(val, yr) {
                    return `${val} in ${yr}`;
                }
                this.chartData.forEach(({ MainValue, Year }, i) => {
                    i === lastIndex
                        ? (desc += ` and ${fmt(MainValue, Year)}.`)
                        : (desc += ` ${fmt(MainValue, Year)},`);
                });
                return desc + ' See the table view for additional details.';
            }
        },
        methods: {
            calcWidth(yearRange) {
                const middleBreakPoint = 1200;
                const smallBreakPoint = 990;
                const [min, max] = yearRange;
                const { outerWidth } = window;
                let width = 300 + (max - min) * 20;
                if (outerWidth > middleBreakPoint) return 1100;
                if (outerWidth < middleBreakPoint && outerWidth > smallBreakPoint)
                    return 940;
                if (width > outerWidth) return width;
                return outerWidth - 30;
            }
        }
    };
</script>

<style lang="scss">
    // .line-graph__wrapper {
    //   display: flex;
    //   justify-content: center;
    // }
</style>
