import * as ENV from '../utils/env';
function toJSON(response) {
  // why do this: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
  if (!response.ok) {
    const { statusText, status } = response;
    throw Error(statusText || status);
  }
  return response.json();
}

// nccd is the proxied by the nginx server. nccd will change to (nccd|nccddev|nccdqa.cdc.gov). See nginx folder
// const ROOT_PATH =
//   '/nccd/nccdproxyInternet/YouthOnlineCrossTabAPI/publicAPI/api';
const ROOT_PATH = ENV.VUE_APP_APIBaseURL;

export function getYears() {
  const uri = `${ROOT_PATH}/Years/2`;
  return fetch(uri).then(toJSON);
}

export function getYATQuestions(year = '2017') {
  const uri = `${ROOT_PATH}/Questions?SurveyId=2&ListOfYears=${year}&ListOfLocations=XX`;
  return fetch(uri).then(toJSON);
}

const TP4_PATH = 'https://www.cdc.gov/TemplatePackage/4.0/includes';
export function getGlobalAlertHTML() {
  return fetch(TP4_PATH + '/global-alert.html').then(resp => resp.text());
}

export function getQuestion({ questionId }) {
  const uri = `${ROOT_PATH}/MMWRQuestions?QuestionId=${questionId}`;
  return fetch(uri).then(toJSON);
}

export function getChartData({ questionId, year, locationId }) {
  if (!year) year = 9999;
  if (!locationId) locationId = 'XX';
  const uri = `${ROOT_PATH}/ChartData?QuestionId=${questionId}&LocationId=${locationId}&Yr=${year}`;
  return fetch(uri).then(toJSON);
}

export function getTableData({ questionId, year, locationId }) {
    const uri = `${ROOT_PATH}/TableData?QuestionId=${questionId}&Yr=${year || 2017}&LocationId=${locationId}`;
  return fetch(uri).then(toJSON);
}

function getFootNotes({ questionId, locationId, viewType }) {
  const uri = `${ROOT_PATH}/FootnotesData?QuestionId=${questionId}&LocationId=${locationId}&ViewType=${viewType}`;
  return fetch(uri).then(toJSON);
}

export function getTableFootNotes({ questionId }) {
  return getFootNotes({ questionId, locationId: 'ZZ', viewType: 'T' });
}

export function getChartFootNotes({ questionId, locationId }) {
  if (!locationId) locationId = 'ZZ'; // returns all locations
  return getFootNotes({ questionId, locationId, viewType: 'C' });
}

export function getMedianRange({ questionId }) {
  const uri = `${ROOT_PATH}/RangeAndMedianData?QuestionId=${questionId}`;
  return fetch(uri).then(toJSON);
}

export function getYearsForLocationAndQuestion({ questionId, locationId }) {
    const uri = `${ROOT_PATH}/questionlocation/years?QuestionId=${questionId}&LocationId=${locationId}`;
    return fetch(uri).then(toJSON);
}

