<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    class="svg-tile-icon"
  >
    <title>Sexual Behaviors</title>
    <path
      :fill="color"
      d="M20.82,35.22H32.88l.44-6.61L35,35.22h5A109.54,109.54,0,0,0,37.46,23s-1.83,3-15.9,3.33Z"
    />
    <path
      :fill="color"
      d="M12.83,4.78a5.67,5.67,0,1,0,5.67,5.67A5.67,5.67,0,0,0,12.83,4.78Z"
    />
    <path
      :fill="color"
      d="M27.07,9.16a5.15,5.15,0,0,0-4.64,7.38,33.61,33.61,0,0,0,9.2.17,5.23,5.23,0,0,0,.59-2.4A5.16,5.16,0,0,0,27.07,9.16Z"
    />
    <path
      :fill="color"
      d="M6.68,27.26l.45,8H18.92l.89-10.74s3.26-.15,6.89-.39c4.51-.3,6.35-.89,7.71-1.47s2.26-1.14,2.24-2.21a2.16,2.16,0,0,0-2.55-2.19,43.71,43.71,0,0,1-7.81.53c-4.74-.09-7.77-1.26-12.88-1.26-6.72,0-8.62,1-10,3.39C1.8,23.52,0,35.22,0,35.22H5Z"
    />
  </svg>
</template>
<script>
export default {
  props: ['color']
};
</script>
le>
