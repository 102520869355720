<template>
    <ul aria-label="Footnotes" class="footnotes">
        <li v-for="(footnote, i) in notes" :key="i">
            <span>{{ footnote.symbol + ' ' }}</span>
            <span v-if="containsIntervalText(footnote.text)">
                <Popper :show="showPopper" placement="top" offsetSkid="150" offsetDistance="35">
                    <template #content>
                        <div style="border: 1px solid black; width: 290px; background-color: white; padding: 8px; text-align: left;">
                            <div style="width:100%; text-align:center;">
                                <img src="../images/95CI.png" />
                            </div>
                            <div>CI = 95% confidence interval which indicates that 95% of the time, the value is expected to fall within this estimated range.</div>
                        </div>

                    </template>
                    <Button style="display:none;">Demo</Button>
                </Popper>CI = <span @mouseover="mouseover" @mouseleave="mouseleave"><a href="#" onclick="return false;">95% Confidence Interval</a> </span>{{removeIntervalText(footnote.text)}}
            </span>
            <span v-else>
                {{capitalize(footnote.text)}}
            </span>
        </li>
        <li>
            
            

        </li>
    </ul>    
</template>

<script>
    import capitalize from './capitalize';
    export default {
        components: {
        },
        props: ['footnotes', 'strat', 'missingData', 'filterCB'],
        data() {
            return {
                showPopper: false,
                message: '95% Confidence Interval'
            };
        },
        computed: {
            notes() {
                // filter callback will cycle through all the footnotes
                // returned from the database and return true or false
                // if they should be shown.
                // example: missing data footnote is always present in the data from the db
                // but for the trend chart we need to check that there is actual
                // missing data before we show it.
                return this.footnotes.filter(this.filterCB(this));
            },

        },
        methods: {
            capitalize,
            mouseover: function () {
                this.showPopper = true;
            },
            mouseleave: function () {
                this.showPopper = false;
            },
            containsIntervalText(text) {
                return text.indexOf('95% confidence interval') > -1 ? true : false;
            },
            removeIntervalText(text) {
                const modifiedText = text.replace('CI = 95% confidence interval', '');
                return capitalize(modifiedText);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .footnotes {
        list-style: none;
        font-size: small;
    }    
    .ci-hover{
    width:100%;
    }
</style>
