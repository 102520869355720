import { csvFormat } from 'd3-dsv';
import { saveAs } from 'file-saver';
import capitalize from '../components/modules/Footnotes/capitalize';

export function csvFootnotes(footnotes, match, includeSymbol = false) {
  const notes = [];
  let missingData = '';
  for (let i = 0; i < footnotes.length; i++) {
    const curr = footnotes[i];
    if (!curr) {
      // sometimes Display Order 1 for example is not present
      // which leaves an 'empty' slot in the array.
      // this empty slot must be empty in the CSV export to maintain
      // footnote order
      notes.push('');
      continue;
    }
    if (curr.text.match(match)) {
      missingData = `${includeSymbol ? curr.symbol : ''}${curr.text}`;
      continue;
    }
    notes.push(curr.symbol + capitalize(curr.text));
  }
  return [notes[0], missingData, notes[1], notes[2]];
}

export function saveCSV(csvData, csvName) {
  const formatted = csvFormat(csvData);
  // the \uFEFF before the formatted string is explained here:
  // https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript
  const blob = new Blob(['\uFEFF' + formatted], {
    type: 'text/html;charset=utf-8'
  });
  saveAs(blob, csvName);
}
