<template>
    <div aria-label="Question" :class="['masonry-tile__wrapper', `yrbs-cat__color--${tileCode}`]">
        <div @click="mainLink" @keyup.enter="mainLink" tabindex="0" role="link">
            <div aria-hidden="true" class="masonry-tile__icon">
                <component :is="currentComponent" color="white" />
            </div>
            <p class="display-7">{{ content }}</p>
        </div>        
        <div class="masonry-tile__links">
            <div @click="graphLinkClick" @keyup.enter="graphLinkClick" tabindex="0" role="link">
                <i aria-hidden="true" class="fas fa-chart-bar"></i>Graphs
            </div>
            <div @click="tableLinkClick" @keyup.enter="tableLinkClick" tabindex="0" role="link">
                <i aria-hidden="true" class="fas fa-table"></i>Tables
            </div>
        </div>
    </div>
</template>

<script>
    import tileDictionary from '../../../utils/tileDictionary.js';
    import * as images from '../../modules/images';
    import * as analytics from '../analyticsHelpers';

    export default {
        components: {
            ...images
        },
        props: ['tileCode', 'content', 'questionCode', 'year', 'searchTerm'],
        computed: {
            currentComponent() {
                return tileDictionary[this.tileCode].component;
            },
            graphLink() {
                const { questionCode, tileCode, year } = this;
                return `/graphs?questionCode=${questionCode}&topicCode=${tileCode}&year=${year}`;
            },
            tableLink() {
                const { questionCode, tileCode, year } = this;
                return `/tables?questionCode=${questionCode}&topicCode=${tileCode}&year=${year}`;
            }
        },
        methods: {
            mainLink() {
                const { $router, graphLink, questionCode, searchTerm } = this;

                const questionCodeAndTerm = searchTerm && searchTerm.length > 0 ? questionCode + ',' + searchTerm : questionCode

                analytics.selectTile({ questionCodeAndTerm: questionCodeAndTerm });
                $router.push(graphLink);
            },
            graphLinkClick() {
                const { $router, graphLink, questionCode } = this;
                analytics.selectGraphs({ questionCode });
                $router.push(graphLink);
            },
            tableLinkClick() {
                const { $router, tableLink, questionCode } = this;
                analytics.selectTables({ questionCode });
                $router.push(tableLink);
            }
        }
    };
</script>

<style lang="scss">
    @import '../../../scss/breakpoints';
    @import '../../../scss/variables';

    .masonry-tile {
        &__wrapper {
            min-height: 150px;
            border-radius: 15px;
            padding: 2px;
            width: 200px;
            box-sizing: border-box;
            max-width: 100%;
            border: 5px solid #fff; // X tiles in the masonry are 10px appart from one another.
            @media screen and (max-width: $md) {
                width: 100%;
            }

            & > [role='link'] {
                cursor: pointer;
            }

            & > * {
                color: white;
            }

            p {
                background: white;
                color: black;
                padding: 10px;
                margin-bottom: 0;
            }
        }

        &__links {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: white;
            margin-top: 2px;
            height: 35px;
            border-radius: 0 0 8px 8px;

            i {
                margin-right: 5px;
            }

            & > [role='link'] {
                text-decoration: underline;
                color: black;

                &:hover {
                    color: $gray;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

        &__icon {
            text-align: center;
            padding: 10px 0;
        }
    }

    .svg-tile-icon {
        height: 40px;
        width: 40px;
    }
</style>
