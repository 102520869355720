<template>
    <div style="width: 100%;">
        <div class="globalAlert" v-html="globalAlert"></div>
        <PageHeader v-if="!printerFriendly" />
        <div @click="backToHome"
             @keyup.enter="backToHome"
             tabindex="0"
             role="link"
             class="pageTitleLink">
            <PageTitle title="YRBS Explorer" />
        </div>
        <main>
            <router-view />
            
        </main>
        <PageFooter v-if="!printerFriendly" />
        <ModalsContainer />
    </div>
    
    
</template>

<script>
    import { ModalsContainer} from 'vue-final-modal';
    import PageHeader from './components/PageHeader.vue';
    import PageTitle from './components/PageTitle.vue';
    import PageFooter from './components/PageFooter.vue';
    import * as helpers from './utils/helpers';
    import { getGlobalAlertHTML } from './utils/api';
    import { selectPageTitle as homeFromHome } from './components/Home/analyticsHelpers';
    import { selectPageTitle as homeFromGraph } from './components/Charts/analyticsHelpers';
    import { selectPageTitle as homeFromTable } from './components/Tables/analyticsHelpers';    
    import '../node_modules/bootstrap/scss/bootstrap.scss';
    import './scss/app.bundle.scss';
    import './custom.scss';
    import './colors.scss';
    import 'vue-final-modal/style.css';
    

    export default {
        data() {
            return {
                globalAlert: ''
            };
        },
        components: {
            PageHeader,
            PageTitle,
            PageFooter,
            ModalsContainer
        },
        mounted() {
            const _this = this;
            this.$store.commit('ui/setMobileView', helpers.isMobile());
            this.$store.commit('ui/setIsIE', helpers.isInternetExplorer());
            window.addEventListener('resize', this.handleResize);
            getGlobalAlertHTML().then(html => {
                _this.globalAlert = html;
            });
        },
        computed: {
            printerFriendly() {
                return false;
                // @TODO return this.$store.getters.printerFriendly;
            }
        },
        methods: {
            handleResize() {
                this.$store.commit('ui/setMobileView', helpers.isMobile());
            },
            backToHome() {
                if (event.target.className === 'help') {
                    event.preventDefault();
                    return;
                }
                const { $router } = this;
                this.$store.dispatch('ui/setTopicCode', null);
                this.$store.dispatch('ui/setSearchTerm', null);
                switch ($router.currentRoute.value.path) {
                    case '/graphs': {
                        homeFromGraph();
                        break;
                    }
                    case '/tables': {
                        homeFromTable();
                        break;
                    }
                    default: {
                        homeFromHome();
                    }
                }
                $router.push('/');
            }
        }
    };
</script>

<style lang="scss" scoped>
pageTitleLink {
  // for the title link
  text-decoration: none;
}
</style>
