import * as api from '../../utils/api';
import { mixTopics } from './helpers';
import pluralize from 'pluralize';

export const state = {
  originalQuestions: [],
  mixedQuestions: [],
  topicMemo: {},
  year: null
};

export const mutations = {
  setQuestions(state, topics) {
    // @TODO fix add formatting to the raw questions response to format it correctly for the state.
    const [original, mixed] = mixTopics(topics);
    state.originalQuestions = original;
    state.mixedQuestions = mixed;
  },
  setTopicMemo(state, newMemo) {
    state.topicMemo = newMemo;
  },
  setYear(state, year) {
    state.year = year;
  }
};

export const actions = {
  getTopics({ state, commit }) {
    commit(
      'ui/setError',
      { type: 'masonryTiles', payload: false },
      { root: true }
    );
    if (state.originalQuestions.length) return; // question are cached
    // can be used be another component to make sure that Topics are there before mixed Questions is called
    commit('ui/setLoading', 'masonryTiles', { root: true });
    api
      .getYears()
      .then(resp => {
        const years = resp[0].Years;
        const latestYear = years[years.length - 1];
        commit('setYear', latestYear);
        api
          .getYATQuestions(latestYear)
          .then(resp => {
            const topics = resp[0].Topics;
            commit('setQuestions', topics);
            commit('ui/setLoaded', 'masonryTiles', { root: true });
          })
          .catch(error => {
            commit(
              'ui/setError',
              { type: 'masonryTiles', payload: error.message },
              { root: true }
            );
            commit('ui/setLoaded', 'masonryTiles', { root: true });
          });
      })
      .catch(error => {
        commit(
          'ui/setError',
          { type: 'masonryTiles', payload: error.message },
          { root: true }
        );
        commit('ui/setLoaded', 'masonryTiles', { root: true });
      });
  }
};

export const getters = {
  filteredByTopicCode(state) {
    return function(topicCode) {
      state.topicMemo = {};
      const filtered = state.originalQuestions.filter(
        q => q.TopicCode === topicCode
      );
      return filtered;
    };
  },
  filteredBySearchTerm(state) {
    return function(searchTerm) {
      const trimmedTerms = searchTerm.trim().split(/\s+/);
      const topicMemo = {};
      const filtered = state.originalQuestions.filter(q => {
        const stringToSearch = q.questionText + ' ' + q.TopicText;
        let include = 0;
        trimmedTerms.forEach(term => {
          const singular = pluralize.singular(term);
          const plural = pluralize.plural(term);
          const regex = new RegExp(singular + '|' + plural, 'i');
          if (stringToSearch.match(regex)) {
            include++;
          }
        });
        if (include === trimmedTerms.length) {
          // Then every search term is present in some part of the stringToSearch
          topicMemo[q.TopicCode] = true;
          return true;
        }
        return false;
      });
      state.topicMemo = topicMemo;
      return filtered;
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
