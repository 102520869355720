<template>
    <div>
        <div @click.prevent="showURLModal" @keyup.enter="showURLModal" tabindex="0" class="m-0 link">
            <i aria-hidden="true" class="fas fa-share"></i>Share Link
        </div>
        <VueFinalModal class="confirm-modal"
                       content-class="confirm-modal-content"
                       v-model="showModal" @opened="selectURL">

            <div class="d-flex modalHeader">
                Share Link
                <a tabindex="3" href="javascript:void(0)" @click="showModal=false" class="modalCloseX"><span aria-label="close" class="sr-only">X</span><i aria-hidden="true" class="fa fa-times"></i></a>
            </div>
           <div class="modal__content">
                <div class="modalInput">
                    <input tabindex="1" class="form-control" v-model="currentURL" ref="inputURL" />
                </div>
                <div class="d-flex copy-paste">
                    <label for="txtURL">Copy and paste URL</label>
                    <input tabindex="2"
                           type="button"
                           id="txtURL"
                           class="btn btn-sm btn-blue-p"
                           value="Copy"
                           @click="copyClicked" />
                </div>
            </div>
        </VueFinalModal>

    </div>
</template>

<script>
    import { selectShareLink as shareTable } from '../Tables/analyticsHelpers';
    import { selectShareLink as shareGraph } from '../Charts/analyticsHelpers';
    import { VueFinalModal } from 'vue-final-modal'
    export default {
        props: ['name'],
        components: {
            VueFinalModal
        },
        data() {
            return {
                currentURL: '',
                showModal: false
            };
        },
        computed: {
            uniqueName() {
                return 'Share-Link' + this.name ? `-${this.name}` : '';
            }
        },
        methods: {
            showURLModal() {
                this.currentURL = window.location.href;
                this.showModal = true;
                const { path, query } = this.$route;
                const { questionCode, year, location } = query;
                if (path === '/tables') {
                    // sent table analytics
                    shareTable({ questionCode, locationCode: location, year });
                } else if (path === '/graphs') {
                    // send graph analytics
                    shareGraph({ questionCode, year });
                }
            },
            closeURLModal() {
                this.showModal = false;
            },
            selectURL() {
                this.$refs.inputURL.select();
            },
            copyClicked() {
                try {
                    this.selectURL();
                    document.execCommand('copy');
                } catch (err) {
                    console.error('Unable to copy');
                }
                this.closeURLModal();
            }
        }
    };
</script>
<style>
    .confirm-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0px;
    }

    .confirm-modal-content {
        display: flex;
        flex-direction: column;
        background: #fff;
        top: 391px;
        left: 651px;
        width: 600px;
        height: auto;
    }

        .confirm-modal-content > * + * {
            margin: 0.5rem 0;
        }

        .confirm-modal-content h1 {
            font-size: 1.375rem;
        }

        .confirm-modal-content button {
            margin: 0.25rem 0 0 auto;
            padding: 0 8px;
            border: 1px solid;
            border-radius: 0.5rem;
        }

    .dark .confirm-modal-content {
        background: #000;
    }
</style>


<style lang="scss">
@import '../../scss/variables';
    .modalHeader {
        background-color: $purple-primary;
        color: white;
        font-size: 1.2em;
        padding: 4px 10px 0 10px;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        .modalCloseX {
            color: white;
            text-decoration: none !important;
        }
    }
.copy-paste {
  padding: 0 15px 15px;
  align-items: center;
  justify-content: space-between;
  & > input {
    background-color: $purple-primary !important;
    border: none;
  }
}
.modalInput {
  padding: 15px 15px 10px 15px;
  width: 100%;
}

    .modal__title {
        background-color: $purple-primary;
        color: white;
        font-size: 1.2em;
        padding: 4px 10px 0 10px;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }
    .modal__close {
        background-color: $purple-primary;
        border: 0px;
        font-size: 1.2em;
    }
</style>

<style scoped>
    ::v-deep .modal-container {
    }

    ::v-deep .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        padding: 0 0 0 0;
        background: #fff;
        width: 600px;
        border: 0px;
    }

    .modal__title {
        margin: 0 0 0 0;
        font-size: 1.2em;
        align-items: center;
        justify-content: space-between;
    }

    .modal__close {
        
        color: white;
        text-decoration: none !important;
    }
</style>

