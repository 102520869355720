<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Physical Activity
    </title>
    <path
      :fill="color"
      d="M5.42,31.52l10.33-.21A1.5,1.5,0,0,0,17,30.59l.74-1.14-3.57-4.9-1,2.37-7.79.85A1.72,1.72,0,0,0,4,29.67,1.73,1.73,0,0,0,5.42,31.52Z"
    />
    <path
      :fill="color"
      d="M10.24,15.92a1.45,1.45,0,0,0,2,.07l4.43-4.56,3.87,1-4.32,5.7c-.59,1-1.76,3-.8,4.64l5,6.87-2.12,7.82a1.72,1.72,0,0,0,1.37,1.79,1.78,1.78,0,0,0,2.26-.62l3.67-10a1.55,1.55,0,0,0-.21-1.4l-3.63-5.27L25.06,18l.15,1.87a1.3,1.3,0,0,0,.49.92c2,1.25,7.64,4.14,7.64,4.14a1.6,1.6,0,0,0,1.89-.77,1.81,1.81,0,0,0,0-2l-5.7-4.44s0-4.33-.08-6c-.07-2.09-2.56-4.09-4.24-4.12-2.41,0-9.67-.25-9.67-.25a2.14,2.14,0,0,0-1.61,1L9.69,13.75A1.65,1.65,0,0,0,10.24,15.92Z"
    />
    <path
      :fill="color"
      d="M31.63,9.32a4.48,4.48,0,1,0-4.48-4.47A4.47,4.47,0,0,0,31.63,9.32Z"
    />
  </svg>
</template>
<script>
export default {
  props: ['color']
};
</script>
