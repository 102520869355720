export { default } from './Footnotes.vue';

function isMissingDataFootnote({ text }) {
  return text.match(/data were not available/);
}

const tableFilter = ({ missingData, strat }) => ft => {
  // if there is missing data then show the Missing Data footnote
  if (isMissingDataFootnote(ft)) return missingData;
  if (strat) {
    // if there is a selected strat then make sure
    // any footnote with a specific strat type is shown
    return ft.stratType === '' || ft.stratType === strat;
  } else {
    // if no strat is selected then do not show footnotes
    // that belong to a specific strat type
    return ft.stratType === '';
  }
};

const chartFilter = ({ missingData }) => ft => {
  // if missingData is true show the missing data footnote
  if (isMissingDataFootnote(ft)) return missingData;
  return true;
};

export { chartFilter, tableFilter };
