import { doInteraction } from '../../utils/analytics';

// G stands for Graph

// L: Location
export function selectLocation({ locationCode, questionCode, year }) {
  // User changes the location
  doInteraction(`YRBS|G|L|${locationCode}|${questionCode},${year}`);
}

// Y: Year
export function selectYear({ year, questionCode, locationCode }) {
  // User changes the year
  doInteraction(`YRBS|G|Y|${year}|${questionCode},${locationCode}`);
}

// Comp: Compare
export function compareLocation({ locationCode, questionCode }) {
    // User changes the location
    doInteraction(`YRBS|G|Comp|T|${questionCode}|${locationCode}`);
}
export function removeCompare({ locationCode, questionCode }) {
    // User changes the location
    doInteraction(`YRBS|G|RComp|T|${questionCode}|${locationCode}`);
}

// CI: Toggle
export function ciToggle({ questionCode, locationCode, year }) {
    // User changes the location
    doInteraction(`YRBS|G|CI|B|${questionCode},${locationCode},${year}`);
}
export function pctToggle({ questionCode, locationCode, year }) {
    // User changes the location
    doInteraction(`YRBS|G|Pct|B|${questionCode},${locationCode},${year}`);
}

// N: Navigation
export function selectPageTitle() {
  // User selects "Youth Risk Behavior Survey Results" page title
  doInteraction('YRBS|G|N|Title');
}

export function selectHelp() {
  // User selects "help"
  doInteraction('YRBS|G|N|Help');
}

export function selectAnotherQuestion() {
  // User selects "Select another question" link
  doInteraction('YRBS|G|N|AQ');
}

export function selectViewTable({ questionCode, locationCode, year }) {
  // User selects "View Table"
  doInteraction(`YRBS|G|N|T|${questionCode},${locationCode},${year}`);
}

export function selectShareLink({ questionCode, locationCode, year }) {
  // User selects "Share Link"
  doInteraction(`YRBS|G|N|SL|${questionCode},${locationCode},${year}`);
}

export function selectViewInYOL({ questionCode, locationCode, year }) {
  // User selects "View in Youth Online" link (US)
  doInteraction(`YRBS|G|N|YOL|${questionCode},${locationCode},${year}`);
}

export function selectScrollToTop() {
  // User scrolls to top
  doInteraction('YRBS|G|N|STT');
}

export function selectOnThisPage({
  scrollToLocation,
  questionCode,
  locationCode,
  year
}) {
  // User selects "On this Page" link
  const pageLocations = {
    lineChart: 'P', // Patterns Over Time
    barChart: 'S' // More Details From a Specific Year
  };
  const loc = pageLocations[scrollToLocation];
  if (loc) {
    doInteraction(`YRBS|G|N|${loc}|${questionCode},${locationCode},${year}`);
  } else {
    console.error('Scroll to location not found: ', scrollToLocation);
  }
}

// P: Print
// CSV: CSV
// T: Trend
// B: Bar
export function trendPDFExport({ questionCode, locationCode }) {
  // User prints/saves - trend chart
  doInteraction(`YRBS|G|P|T|${questionCode},${locationCode}`);
}

export function trendCSVExport({ questionCode, locationCode }) {
  // User exports (CSV) - trend chart
  doInteraction(`YRBS|G|CSV|T|${questionCode},${locationCode}`);
}

export function barPDFExport({ questionCode, locationCode, year }) {
  // User prints/saves - bar chart
  doInteraction(`YRBS|G|P|B|${questionCode},${locationCode},${year}`);
}

export function barCSVExport({ questionCode, locationCode, year }) {
  // User exports (CSV) - bar chart
  doInteraction(`YRBS|G|CSV|B|${questionCode},${locationCode},${year}`);
}
