<template>    
    <OverFlowX>
        <table v-bind:id="getTableId()"
               :aria-label="label"
               :class="['table table-striped', 'totaled']">
            <caption>{{ label || 'total' }}</caption>
            <colgroup>
                <col style="width: 20%" />
                <col v-for="name in headers" span="2" :style="width" :key="name" />
            </colgroup>
            <thead>
                <tr>
                    <td :class="['table-type--none']"></td>
                    <th v-for="name in headers"
                        :key="name"
                        scope="colgroup"
                        colspan="2"
                        :style="{
              background: color
            }">{{ name }}</th>
                </tr>
                <tr>
                    <th scope="col" :class="[ !label ? 'table-type--none' : '']">{{ label !=='Total' ? label.replace('2',''): ''}}</th>
                    <HeaderPercentCI v-for="name in headers" :key="name + '1'" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="(stratType, stratName) in tableData" class="tableData"
                    :id="getLocationCode(stratName)"
                    tabindex="-1"
                    :key="stratName" 
                    :class="[ stratName ==='Total' ? 'istotal' : '']"
                    >
                    <th scope="row">
                        <span>{{ stratName }}</span>
                    </th>
                    <DataPercentCI v-for="value in orderRowData(stratType)" v-if="!isStateOrLocal"
                                   :key="stratName + value.key"
                                   :percent="value.MainValue ? value.MainValue : '—'"
                                   :ci="value.LowCI ? `${value.LowCI} - ${value.HighCI}` : '—'" />
                    <DataPercentCI v-for="value in orderRowDataStateLocal(stratType)" v-if="isStateOrLocal"
                                   :key="stratName + value.key"
                                   :percent="value.MainValue ? value.MainValue : '—'"
                                   :ci="value.LowCI ? `${value.LowCI} - ${value.HighCI}` : '—'" />
                </tr>
                <tr v-if="medianRange" class="medianRange">
                    <th scope="row">Median</th>
                    <td colspan="2"
                        v-for="value in orderRowDataMedian(medianRange)"
                        :key="'median' + value.key">
                        {{ value.MedianValue }}
                    </td>
                </tr>
                <tr v-if="medianRange" class="medianRange">
                    <th scope="row">Range</th>
                    <td colspan="2" v-for="value in orderRowDataMedian(medianRange)" :key="'range' + value.key">
                        {{ value.MinValue }}{{
 value.MinValue ? ' - ' : ''
                        }}{{ value.MaxValue }}
                    </td>
                </tr>
            </tbody>
        </table>

    </OverFlowX>
</template>

<script>
    import OverFlowX from '../../modules/OverFlowX.vue';
    import { HeaderPercentCI, DataPercentCI } from './tableHelpers';
    import tileDictionary from '../../../utils/tileDictionary';
    import locationDictionary from '../../../utils/locationDictionary';
    export default {
        components: {
            OverFlowX,
            HeaderPercentCI,
            DataPercentCI
        },
        data() {
            return {
                color: '',                
            };
        },
        props: ['strat', 'tableData', 'headers', 'showLocationLink', 'label', 'medianRange', 'id', 'textLabel'],
        mounted() {
            const { topicCode } = this.$route.query;
            this.color = topicCode ? tileDictionary[topicCode].color : '';
        },
        updated() {
            
        },
        computed: {
            isStateOrLocal() {
                if (this.id === 'state' || this.id === 'local') return true;
                return false;
            },
            width() {
                const colWidth = Math.floor(80 / this.headers.length / 2);
                return `width: ${colWidth}%`;
            },
        },
        methods: {
            limitArray(arr) {
                const arr2 = [];

                for (const key in arr) {
                    const cur = arr[key];

                    arr2[key] = {
                        ...cur
                    };
                }

                return arr;
            },
            getTableId() {
                return this.id + '-' + this.label;
            },
            orderRowData(stratType) {
                const obj = this.strat ? stratType[this.strat] : stratType;
                const arr = [];
                for (const key in obj) {
                    const cur = obj[key];
                    const order = cur.StratOrder || cur.Total.StratOrder;
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },

            orderRowDataStateLocal(stratType) {
                const obj = this.strat ? (this.strat === "Total" ? stratType[this.strat].Total : stratType[this.strat]) : stratType;
                const arr = [];
                for (const key in obj) {
                    const cur = this.strat !== "Total" ? obj[key].Total : obj[key];
                    const order = this.strat !== "Total" ? cur.StratOrder2 : cur.StratOrder
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },
            orderRowDataMedian(stratType) {
                let obj = (this.strat === "Total" || this.strat === "") ? stratType.Sex : stratType[this.strat];
                const arr = [];
                for (const key in obj) {
                    const cur = obj[key];
                    const order = cur.StratOrder;
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                }
                return arr;
            },
            getLocationCode(locationName) {
                return locationDictionary.byName()[locationName];
            },
        }
    };
</script>

<style lang="scss">
    @import '../../../scss/variables';
    $lborder: 1px solid black;
    $bborder: 4px solid black;


    </style>
