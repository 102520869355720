import * as api from '../../utils/api';

const state = {
    loaders: {
        masonryTiles: false
    },
    errors: {
        masonryTiles: false,
        table: false
    },
    topicCode: null,
    searchTerm: null,
    isMobile: false,
    isIE: false,
    QuestionCode: '',
    Question: '',
    ShortQuestionText: '',
    NativeDirection: '',
    QuestionYears: [],
    LocationId: null
};

const mutations = {
    setLoading(state, loader) {
        //state.loaders[loader] = true;
        Reflect.set(state.loaders, loader, true);
    },
    setLoaded(state, loader) {
        //state.loaders[loader] = false;
        Reflect.set(state.loaders, loader, false);
    },
    setMobileView(state, isMobile) {
        state.isMobile = isMobile;
    },
    setIsIE(state, isIE) {
        state.isIE = isIE;
    },
    setError(state, action) {
        const { type, payload } = action;
        Reflect.set(state.errors, type, payload);
    },
    setTopicCode(state, topicCode) {
        state.topicCode = topicCode;
    },
    setSearchTerm(state, searchTerm) {
        state.searchTerm = searchTerm;
    },
    removeError(state, type) {
        Reflect.set(state.errors, type, false);
    },
    setQuestion(
        state,
        {
            Question,
            ShortQuestionText,
            NativeDirection,
            QuestionYears,
            QuestionCode,
            LocationId
        }
    ) {
        state.Question = Question;
        state.QuestionCode = QuestionCode;
        state.ShortQuestionText = ShortQuestionText;
        state.LocationId = LocationId;
        state.NativeDirection = NativeDirection;
        state.QuestionYears = QuestionYears.split(', ');
    },
    setLocation(
        state,
        {
            LocationId
        }
    ) {
        state.LocationId = LocationId;
    },
    setStateLocalYears(state, { StateLocalYears }) {
        state.StateLocalYears = StateLocalYears.split(', ');
    },
    removeQuestion(state) {

        state.LocationId = '';
        state.Question = '';
        state.QuestionCode = '';
        state.ShortQuestionText = '';
        state.NativeDirection = '';
        state.QuestionYears = [];
        state.StateLocalYears = [];
    }
};

const actions = {
    getQuestion({ commit }, questionId) {
        return new Promise(resolve => {
            api.getQuestion({ questionId }).then(([question]) => {
                commit('setQuestion', { ...question, QuestionCode: questionId });
                resolve();
            });
        });
    },
    setTopicCode({ commit }, topicCode) {
        commit('setTopicCode', topicCode);
    },
    setSearchTerm({ commit }, searchTerm) {
        commit('setSearchTerm', searchTerm);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
