import { scaleLinear } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { line } from 'd3-shape';
import { createSVG, svgGTranslate } from '../d3.helpers';

export function createLineGraph(vm) {
  const graph = new LineGraph(vm);
  graph
    .createXAxis()
    .createYAxis()
    .createLine()
    .createDots()
    .createDataPoints();
}

export class LineGraph {
  constructor(vm) {
    this.width = vm.width;
    this.scales = this.createScales(vm);
    this.svg = createSVG(vm);
    this.chartData = vm.chartData;
    this.color = vm.color;
    this.margin = vm.margin;
    this.height = vm.height;
  }

  createScales(vm) {
    const { margin, height, yearRange, width } = vm;
    const [min, max] = yearRange;
    const x = scaleLinear()
      .domain([min - 1, max + 2])
      .range([0, width - margin.right - margin.left]);
    const y = scaleLinear()
      .domain([0, 100])
      .range([height - margin.top - margin.bottom, 0]);
    return { x, y };
  }

  createYAxis() {
    const { svg, scales, width, margin, height } = this;
    // add axis
    svg
      .append('g')
      .call(axisLeft(scales.y).tickValues([0, 20, 40, 60, 80, 100]))
      .call(g => {
        g.selectAll('.tick:not(:first-of-type) line')
          .attr('stroke-opacity', 0.1)
          .attr('x2', width - margin.right - margin.left + 6)
          .attr('transform', 'translate(-6, 0)');
        g.selectAll('.tick text').attr('font-size', 17);
      });
    // add Percentage label
    svg
      .append('text')
      .attr('transform', `rotate(-90) translate(-${height / 2}, -50)`)
      .attr('font-size', '15')
      .attr('font-weight', 'bold')
      .text('Percentage (%)');

    return this;
  }

  createXAxis() {
    const { svg, scales, height, margin, chartData } = this;
    const translation = { x: 0, y: height - margin.top - margin.bottom };
    const tickValues = [];
    for (let i = 0; i < chartData.length; i++) {
      const d = chartData[i];
      if (i > 0) {
        const prevData = chartData[i - 1];
        if (+prevData.Year - +d.Year > 2) {
          let currYear = +prevData.Year - 2;
          while (currYear !== +d.Year) {
            tickValues.push(currYear.toString());
            currYear -= 2;
          }
        }
      }
      tickValues.push(d.Year);
    }
    svgGTranslate(svg, translation)
      .call(
        axisBottom(scales.x)
          .tickValues(tickValues)
          .tickFormat(format('d'))
      )
      .call(g => {
        g.selectAll('.tick text').attr('font-size', 14);
      });
    return this;
  }

  createLine() {
    const { svg, scales, chartData, color } = this;
    const data = [];
    for (let i = 0; i < chartData.length; i++) {
      const d = chartData[i];
      if (i > 0) {
        const prevData = chartData[i - 1];
        if (+prevData.Year - +d.Year > 2) {
          let currYear = +prevData.Year - 2;
          while (currYear !== +d.Year) {
            data.push([scales.x(currYear.toString()), null]);
            currYear -= 2;
          }
        }
      }
      data.push([scales.x(d.Year), scales.y(d.MainValue)]);
    }
    //debugger;
    const _line = line().defined(d => d[1] !== null);
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .attr('d', _line(data));
    return this;
  }

  createDots() {
    const { svg, scales, chartData } = this;
    svg
      .append('g')
      .selectAll('dot')
      .data(chartData)
      .enter()
      .append('circle')
      .attr('cx', function(d) {
        return scales.x(d.Year);
      })
      .attr('cy', function(d) {
        return scales.y(d.MainValue);
      })
      .attr('r', 5)
      .attr('fill', '#000');
    return this;
  }

  createDataPoints() {
    const { svg, scales, chartData, color } = this;
    svg
      .append('g')
      .selectAll('dot')
      .data(chartData)
      .enter()
      .append('text')
      .attr('fill', color)
      .attr('font-weight', 'bold')
      .attr('x', function(d) {
        return scales.x(d.Year) - 10;
      })
      .attr('y', function(d) {
        return scales.y(d.MainValue) - 15;
      })
      .text(function(d) {
        if (d.MainValue === '') return 'N/A';
        return format('.1f')(d.MainValue);
      });
    return this;
  }
}
