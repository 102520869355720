import * as helpers from '../../utils/helpers';

export const mixTopics = function(topics) {
  let newTopics = [];
  topics.forEach(topic => {
    const { TopicCode, TopicText } = topic;
    topic.TopicQuestions.forEach(question => {
      const {
        GreaterRiskQuestionText,
        LessRiskQuestionText,
        NativeDirection
      } = question;
      let questionText = '';
      if (NativeDirection === 'Negative') {
        questionText = GreaterRiskQuestionText;
      } else {
        questionText = LessRiskQuestionText;
      }
      const newQuestion = {
        ...question,
        questionText
      };
      newTopics.push({ TopicCode, TopicText, ...newQuestion });
    });
  });
  return [newTopics, helpers.shuffle([...newTopics])]; // need helpers.shuffle is destructive so need to send it a copy of newTopics array
};
