<template>
    <div v-if="color" class="row sticky-header__wrapper">
        <div class="sticky-header__title d-print-none">
            <div class="link" @click="backToHome" @keyup.enter="backToHome" tabindex="0" role="link">
                <span>
                    <i aria-hidden="true" class="fas fa-arrow-left"></i>Select another
                    question
                </span>
            </div>
            <div v-if="jumpTo && jumpTo.length" class="sticky-header__title--jump-to">
                <h4>On this page:&nbsp;</h4>
                <p role="link"
                   tabindex="0"
                   class="link"
                   v-for="link in jumpOptions"
                   :key="link.text"
                   @click="link.cb"
                   @keyup.enter="link.tab">{{ link.text }}</p>
            </div>
        </div>
        <div class="sticky-header__topic--wrapper" :style="{ border: '4px solid ' + color }">
            <div class="sticky-header__topic--text">
                <div aria-label="Topic" :style="{ backgroundColor: color, border: '0px', borderRadius: '10px 10px 0 0' }">
                    <component :is="currentComponent" color="#fff" />
                    {{ topicText }}
                </div>
                <p aria-label="Question"
                   v-if="questionText">{{ 'high school students who ' + questionText }}</p>
            </div>
            <div aria-hidden="true" class="sticky-header__topic--slot d-print-none">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import tileDictionary from '../../utils/tileDictionary';
    import * as images from './images';
    import { selectAnotherQuestion as otherQuestionGraph } from '../Charts/analyticsHelpers';
    import { selectAnotherQuestion as otherQuestionTable } from '../Tables/analyticsHelpers';
    import { doAnalytics } from '../../utils/analytics';

    export default {
        props: ['topicCode', 'jumpTo', 'page'],
        components: { ...images },
        data() {
            return {
                color: '',
                topicClass: '',
                topicText: ''
            };
        },
        mounted() {
            const { color, text, className } = tileDictionary[this.topicCode];
            this.color = color;
            this.topicText = text;
            this.topicClass = className;
        },
        updated() {
            if (this.$store.state.ui.ShortQuestionText) {
                const title = `${this.$store.state.ui.ShortQuestionText} | YRBS-${this.page} | CDC`;
                if (document.title !== title) {
                    // make sure it only fires once
                    document.title = title;
                    doAnalytics({
                        levels: ['cdc', 'dash', 'yrbs'],
                        channel: 'YRBS Explorer'
                    });
                }
            }
        },
        computed: {
            jumpOptions() {
                return this.jumpTo.filter(opt => opt.show);
            },
            currentComponent() {
                if (!this.topicCode) return null;
                return tileDictionary[this.topicCode].component;
            },
            questionText() {
                return this.$store.state.ui.Question;
            }
        },
        methods: {
            backToHome() {
                const path = this.$router.currentRoute.value.path;
                if (path === '/graphs') {
                    otherQuestionGraph();
                } else if (path == '/tables') {
                    otherQuestionTable();
                } else {
                    console.error('Could not do analytics for path: ', path);
                }
                this.$router.push('/');
            }
        }
    };
</script>

<style lang="scss">
    @import '../../scss/variables';
    @import '../../scss/breakpoints';

    .sticky-header {
        &__wrapper {
            z-index: 100;
            position: sticky;
            top: 0;
            margin: 0;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 5px;

            @media print {
                position: relative;
                font-size: small;
            }
        }


        &__title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 10px 0;

            i {
                margin-right: 5px;
            }

            &--jump-to {
                display: flex;

                & > * {
                    margin: 0 10px;
                }

                h4 {
                    font-family: 'Open Sans';
                    font-size: 17px;
                }

                p {
                    color: $link;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }

        &__topic {
            &--wrapper {
                width: 100%;
                border-radius: 20px;
                background: white;
                margin-left: 0;
                margin-right: 0;
                padding: 0px;
            }

            &--text {
                text-transform: capitalize;

                & > div {
                    & > svg {
                        padding: 5px;
                    }

                    border-radius: 15px 15px 0px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    height: 40px;
                    color: white;
                }

                & > span {
                    line-height: 3;
                    font-weight: bold;
                    white-space: nowrap;
                    margin-right: 20px;
                }

                & > p {
                    margin: 0;
                    text-align: center;
                    font-size: 1.2em;
                    padding: 10px;
                    font-weight: bold;
                }

                @media screen and (max-width: $md) {
                    display: block;
                    font-size: 14px;
                    //float: right;
                }
            }

            &--background {
                border: 3px solid $light;
            }

            &--slot {
                border-top: 1px solid #ddd;
                background: #f8f9fa;
                border-radius: 0 0 18px 18px;
                display: flex;
                justify-content: flex-start;
                padding: 10px;
                /* Medium Devices, Desktops */
                @media (max-width: $lg) {
                    display: block;
                }
            }
        }
    }
</style>
