<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Obesity, Overweight and Weight Control
    </title>
    <path
      :fill="color"
      d="M32.82,9H30.58A10.84,10.84,0,0,0,9.42,9H7.18a4.63,4.63,0,0,0-4.67,5L4,34.49a5.5,5.5,0,0,0,5.39,5H30.63a5.5,5.5,0,0,0,5.39-5L37.49,14A4.63,4.63,0,0,0,32.82,9ZM20,3.45a7.9,7.9,0,1,1-7.89,7.9A7.91,7.91,0,0,1,20,3.45ZM21.4,35a1.4,1.4,0,0,1-2.8,0V25.31a1.4,1.4,0,0,1,2.8,0Z"
    />
    <path
      :fill="color"
      d="M18.69,10.23a1.7,1.7,0,0,0-.42,1.12,1.73,1.73,0,1,0,2.1-1.69L18.81,5.34c-.19-.52-.37-.52-.44-.52h-.08c-.13,0-.26.15-.19.69Z"
    />
  </svg>
</template>
<script>
export default {
  props: ['color']
};
</script>
