<template>
  <div class="masonry__wrapper" style="width: 100%">    
    <Loader v-if="loading" :key="topicCode || searchTerm" style="top: 0; left: 0; right: 0; bottom: 0;" />
    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
    <div v-if="!questions.length && !loading">No Results</div>
    <div
      v-masonry
      :transition-duration="transition"
      item-selector=".masonry-item"
      :origin-top="true"
      :horizontal-order="false"
      :fit-width="true"         
    >
      <div
        v-masonry-tile
        class="masonry-item"
        v-for="question in questions"
        :key="question.TopicCode + question.QuestionCode"           
      >
        <MasonryTile
          :tileCode="question.TopicCode"
          :content="question.questionText"
          :questionCode="question.QuestionCode"
          :searchTerm ="searchTerm"
          :year="year"                     
        />
      </div>
    </div>
  </div>
</template>

<script>
    import MasonryTile from './modules/MasonryTile.vue';
    import Loader from '../modules/Loader.vue';
    import { mapState } from 'vuex';

    export default {
        components: {
            MasonryTile,
            Loader
        },
        props: {
            topicCode: String,
            searchTerm: String,
            otherloading: Boolean,
            year: String
        },
        methods: {
            repaint() {
                setTimeout(() => this.$redrawVueMasonry(), 100);
            }
        },
        computed: {
            ...mapState({
                loading: state => state.ui.loaders.masonryTiles,
                transition: state => (state.ui.isIE ? '0s' : '0.5s'),
                error: state => state.ui.errors.masonryTiles
            }),
            questions() {
                const { searchTerm, topicCode, $store } = this;
                if (topicCode) {
                    return $store.getters['questions/filteredByTopicCode'](topicCode);
                } else if (searchTerm) {
                    return $store.getters['questions/filteredBySearchTerm'](searchTerm);
                } else {
                    return $store.state.questions.mixedQuestions;
                }
            }            
        },
        watch: {
            questions: function () {
                this.repaint();
            }
        },
    };
</script>

<style lang="scss">
@import '../../scss/breakpoints';
.masonry {
  &__wrapper {
    min-height: 620px;
    margin-top: 5px;
    width: 100%;
    @media screen and (max-width: $md) {
      & > div {
        width: 100% !important;
      }
      & > div > div {
        width: 50%;
        &.spinner-border {
          width: 2rem;
        }
      }
    }
  }
}
</style>
