import { h } from 'vue'
export const HeaderPercentCI = {
    functional: true,
    render: () => [
        h('th', { attrs: { scope: 'col' } }, '%'),
        h('th', { attrs: { scope: 'col' } }, 'CI')
    ]
};

export const DataPercentCI = (props) => {

    return [
        h('td', { class: '' }, props ? props.percent : ''),
        h('td', { class: '' }, props ? props.ci : '')
    ]
};

DataPercentCI.props = ['percent', 'ci']