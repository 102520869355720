<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Dietary Behaviors
    </title>
    <path
      :fill="color"
      d="M7.48.91a.79.79,0,0,0-1.27,0C4,4.4,11.79,15.17,14.86,19.2a2,2,0,0,0,1.86.78h.12a2.11,2.11,0,0,1,2.07,1C24.35,29.4,30,39.22,30,39.22a.71.71,0,0,0,.49.36,4.43,4.43,0,0,0,2.17-.77A4.34,4.34,0,0,0,34.08,37a.68.68,0,0,0-.15-.56l0,0a166,166,0,0,1-13.81-18C16.71,13.25,9.35,3.06,7.48.91Z"
    />
    <path
      :fill="color"
      d="M28,15.06l.6,0a.59.59,0,0,0,.54-.26L36.24,4.37A.31.31,0,0,0,36.16,4h0a.28.28,0,0,0-.41.07l-5.4,7.41a.49.49,0,0,1-.67.11h0a.47.47,0,0,1-.12-.66l5.12-7.61a.29.29,0,0,0-.08-.41h0a.3.3,0,0,0-.41.07l-5.4,7.42a.48.48,0,0,1-.66.11h0A.49.49,0,0,1,28,9.76l5.12-7.6A.3.3,0,0,0,33,1.74h0a.28.28,0,0,0-.41.07l-5.4,7.41a.48.48,0,0,1-.66.11h0a.48.48,0,0,1-.12-.66L31.5,1.05a.29.29,0,0,0-.08-.41h0A.31.31,0,0,0,31,.7L23.59,11a.64.64,0,0,0-.06.6l.25.54a3.61,3.61,0,0,1-.4,3.65c-.52.7-1.07,1.42-1.63,2.16.35.54.71,1.08,1.07,1.61.63-1,1.25-1.95,1.85-2.86A3.61,3.61,0,0,1,28,15.06Z"
    />
    <path
      :fill="color"
      d="M6.9,36.47a.69.69,0,0,0-.15.57,4.45,4.45,0,0,0,3.62,2.54.66.66,0,0,0,.47-.32l0,0s4.12-7.05,8.58-14.3L18,22.71C12.54,29.68,6.9,36.47,6.9,36.47Z"
    /></svg>
</template>
<script>
export default {
  props: ['color']
};
</script>
