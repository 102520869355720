<template>
    <div class="nested-dropdown" v-click-outside="unfocus">
        <div role="button"
             id="selectedItem"
             tabindex="0"
             :aria-labelledby="labelId + ' selectedItem'"
             aria-haspopup="tree"
             :aria-expanded="focused"
             aria-keyshortcuts="alt+down"
             @click="focused = !focused"
             ref="selectOption"
             class="nested-dropdown__option"
             @keyup.alt.arrow-down="handleDropdown">
        {{ selectedName }}
        <span aria-hidden="true">&#9660;</span>
    </div>
    <ul ref="selectGroups"
        role="tree"
        tabindex="-1"
        :aria-labelledby="labelId"
        v-if="focused"
        class="nested-dropdown__option"
        @keyup.arrow-left="arrowLeft"
        @keyup.arrow-up="arrowUp"
        @keyup.arrow-right="arrowRight"
        @keyup.arrow-down="arrowDown"
        @keydown="
        e=>
        {
        e.preventDefault();
        }
        "
        @keyup.enter="onEnter"
        >
        <li role="treeitem"
            v-for="($options, $group) in options"
            tabindex="-1"
            :id="$group"
            :key="$group"
            :label="$group"
            :ref="$group"
            @click="$group ==='All' ? this.handleChange($group) : this.selectGroup($group)"
            :class="
          $group !== userSelectedGroup
            ? 'nested-dropdown__group--hidden'
            : 'nested-dropdown__group' +
          $group ==='All' ? 'ShowAllItem' : ''
        ">
            <span aria-hidden="true" v-if="$group !='All'">
                {{
          $group !== userSelectedGroup ? '&#9656;' : '&#9662;'
                }}
            </span>
            <span>{{ $group ==='All' ? 'All Locations': $group }}</span>
            <ul role="group" tabindex="-1" :aria-labelledby="labelId + ' ' + $group" v-if="$group !=='All'">
                <li tabindex="-1"
                    role="treeitem"
                    v-for="(value, $key) in $options"
                    :key="value"
                    :ref="$group + $key"
                    @click="this.handleChange($key)"
                    :aria-selected="$key === selected"
                    :data-value="$key">
                    <span aria-hidden="true" v-if="$key === selected">&#10004;</span>
                    {{ value }}
                </li>
            </ul>
        </li>
    </ul>
    </div>
</template>

<script>
    import locationDictionary from '../../../utils/locationDictionary';

    export function getInitialSelection(selected) {
        for (let $key in locationDictionary.byCode) {
            const options = locationDictionary.byCode[$key]; // National, State, or Local School Districts
            if (Object.keys(options).includes(selected)) {
                return $key;
            }
        }
        return '';
    }

    export default {
        props: {
            onchange: Function,
            selected: String,
            labelId: String,
            showAllLocationItem: Boolean
        },
        data() {
            const group = getInitialSelection(this.selected);
            return {
                group,
                userSelectedGroup: group,
                focused: false,
                userSelectedOption: group + this.selected
            };
        },
        computed: {
            selectedName() {
                return this.selected === 'All' ? 'All Locations' : locationDictionary.byCodeFlat()[this.selected];
            },
            options() {
                var items = Object.assign({}, locationDictionary.byCode);
                if (!this.showAllLocationItem) delete items.All;
                return items;
            }
        },
        methods: {
            focus() {
                this.focused = true;
            },
            unfocus() {
                // used for the closeable plugin
                this.userSelectedGroup = this.group;
                this.userSelectedOption = this.group + this.selected;
                this.focused = false;
            },
            toggleFocus() {
                if (!this.focused) {
                    this.focused = true;
                    return;
                }
                this.userSelectedGroup = this.group;
                this.userSelectedOption = this.group + this.selected;
                this.focused = false;
            },
            selectGroup($group) {
                this.userSelectedGroup = this.userSelectedGroup === $group ? '' : $group;
            },
            getCurrentLocation() {
                return this.$refs[this.userSelectedGroup + this.selected][0];
            },
            handleDropdown() {
                this.focused = true;
                this.$nextTick(() => {
                    this.getCurrentLocation().focus();
                });
            },
            getGroupLocationRefNames(group) {
                const regex = new RegExp(`${group}.+`);
                return Object.keys(this.$refs).filter(name => name.match(regex));
            },
            getNameAndRefs() {
                if (!this.userSelectedGroup) {
                    // at group level
                    let bc = locationDictionary.byCode;
                    if (!this.showAllLocationItem) delete bc.All;

                    return [
                        this.userSelectedOption,
                        Object.keys(bc)
                    ];
                } else {
                    // at location level
                    const refs = this.getGroupLocationRefNames(this.userSelectedGroup);
                    return [this.userSelectedOption, refs];
                }
            },
            arrowUp(e) {
                if (e.altKey) {
                    this.toggleFocus();
                    return;
                }
                const [currentName, refs] = this.getNameAndRefs();
                // if already at the top do nothing
                const first = this.$refs[refs[0]][0];
                const current = this.$refs[currentName][0];
                if (current === first) return;
                // move focus to the above element
                const currentIndex = refs.indexOf(currentName);
                const prevName = refs[currentIndex - 1];
                this.userSelectedOption = prevName;
                this.$refs[prevName][0].focus();
            },
            arrowDown(e) {
                if (e.altKey) {
                    this.toggleFocus();
                    return;
                }
                const [currentName, refs] = this.getNameAndRefs();
                // if already at the bottom do nothing
                const last = this.$refs[refs[refs.length - 1]][0];
                const current = this.$refs[currentName][0];
                if (current === last) return;
                // select the element below the current element.
                const currentIndex = refs.indexOf(currentName);
                const nextName = refs[currentIndex + 1];
                this.userSelectedOption = nextName;
                this.$refs[nextName][0].focus();
            },
            arrowLeft() {
                // if already at group level then do nothing
                const group = this.userSelectedGroup;
                if (!group) return;
                // close current selected group and move focus to the group level
                this.userSelectedGroup = '';
                this.userSelectedOption = group;
                this.$nextTick(() => {
                    this.$refs[group][0].focus();
                });
            },
            arrowRight(e) {
                // if already at the location level do nothing
                if (this.userSelectedGroup) return;
                // if at the group level select the current focused groups first item
                const groupName = e.target.getAttribute('label');
                const firstName = this.getGroupLocationRefNames(groupName)[0];
                this.userSelectedGroup = groupName;
                this.userSelectedOption = firstName;
                this.$nextTick(() => {
                    this.$refs[firstName][0].focus();
                });
            },
            handleChange(value) {
                // the below todos are probably being handled by 'unfocus'
                // which is triggered by the closable plugin.
                this.group = this.userSelectedGroup; // @TODO remove
                this.focused = false; // @TODO remove
                this.onchange(value);
            },
            onEnter(e) {
                // if on a location select the location & group
                if (!this.userSelectedGroup) return;
                this.handleChange(e.target.dataset.value);
            }
        }
    };
</script>

<style lang="scss">
    @import '../../../scss/variables';
    $maxHeight: 375px;
    $selectWidth: 325px;
    $optionsWidth: 375px;
    $padding: 7px 15px;

    .nested-dropdown {
        li {
            list-style: none;
            cursor: pointer;

            &:focus {
                border: 2px solid orange;
            }
        }

        &__option {
            padding: $padding;
            background: white;
            border: 1px solid $gray;
            border-radius: 5px;
            width: $selectWidth;
            cursor: pointer;
        }

        & > div > span {
            font-size: 10px;
            float: right;
            padding-top: 5px;
        }

        & > ul {
            max-height: $maxHeight;
            overflow-y: scroll;
            position: absolute;
            z-index: 3;
            width: $optionsWidth;

            & > li {
                font-weight: bold;

                & > ul > li {
                    position: relative;
                    font-weight: normal;
                    white-space: nowrap; // important! click behavior will miss click if any options wrap unexpectedly
                    & > span {
                        position: absolute;
                        left: -20px;
                    }
                }
            }
        }

        &__group {
            &--hidden {
                & > ul {
                    display: none;
                }
            }
        }

        .ShowAllItem {
            margin-left: 20px;
        }
    }
</style>
