<template>
  <div class="nested-dropdown__container">
    <label :id="labelId">{{ label }}</label>
    <Dropdown :onchange="onchange" :selected="selected" :labelId="labelId" :showAllLocationItem="showAllLocationItem" />
  </div>
</template>

<script>
import Dropdown from './NestedDropdown.vue';
export default {
        props: ['onchange', 'selected', 'label','showAllLocationItem'],
  components: { Dropdown },
  computed: {
    labelId() {
      return this.label.replace(' ', '-') + '-dropdown';
    }
  }
};
</script>

<style lang="scss">
$padding: 7px 15px;
.nested-dropdown__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  & > label {
    padding: $padding;
    margin: 0;
  }
}
</style>
