import { containsInNestedObject } from '../../utils/helpers';

export function getHeaders(dataObject) {
    const headers = [];
    for (const key in dataObject) {
        const val = dataObject[key];
        headers[val.StratOrder - 1] = key;
    }
    return headers;
}
export function getHeadersAllLocations(dataObject) {
    const headers = [];
    let index = 0;
    for (const key in dataObject) {
        if (key && key.length > 0 && key !== '') {
            headers[index] = key;
            index += 1;
        }
    }
    return headers;
}
export function getStateLocalHeaders(dataObject) {
    const headers = [];
    for (const key in dataObject) {
        const val = dataObject[key];
        headers[val.Total.StratOrder2 - 1] = key;
    }
    return headers;
}
export function hasEmptyValues(tableData, strat) {
    let filtered = {};
    if (strat) {
        for (const key in tableData) {
            // key = state/local name
            const value = tableData[key];
            for (const $key in value) {
                // $key = stratType
                if ($key === strat) {
                    if (!filtered[key]) filtered[key] = {};
                    filtered[key][$key] = tableData[key][strat];
                }
            }
        }
        return containsInNestedObject(filtered, '');
    }
    return containsInNestedObject(tableData, '');
}


export function formatYearData({ yearData }) {

    
    let years = [];
    yearData.sort((a, b) => a > b ? 1 : -1).forEach((item, index) => {
        years[index] = item.Year;
    });

    return years;
}