<template>
    <div aria-label="Filters"
         :class="[
      'col-12 col-md-4 col-lg-3',
      'filter__wrapper',
      !isIE && 'filter__wrapper--sticky'
    ]">
        <form aria-label="Search" @submit.prevent="setSearchTermTimeout">
            <label for="searchForQuestions">Search Questions</label>
            <div class="link" style="float:right;margin-top:-9px;"
                 role="button"
                 tabindex="0"
                 @click="seeAll"
                 @keyup.enter="seeAll">
                Reset
            </div>
            <div style="margin-bottom:17px;">
                <input type="text"
                       class="form-control"
                       id="searchForQuestions"
                       ref="searchTermInput"
                       @keyup="setSearchTermTimeout" />

            </div>
        </form>

        <label id="selectTopic">Select Topic</label>
        <div role="listbox"
             :aria-activedescendant="$parent.topicCode"
             aria-labelledby="selectTopic">
            <div :class="['filter__button', getTileColor(name)]"
                 tabindex="0"
                 role="option"
                 :aria-selected="tileIsSelected(name)"
                 v-for="(tile, name) in tileDictionary"
                 :key="name"
                 :id="name"
                 @click="selectTopic(name)"
                 @keyup.enter="selectTopic(name)">
                <div aria-hidden="true">
                    <component :is="getCurrent(name).component" color="white" />
                </div>
                <div>{{ tile.text }}</div>
            </div>
        </div>
        <div tabindex="0"
             @keyup.enter="seeAll"
             class="filter__see-all"
             role="button"
             @click="seeAll">
            See All Topics
        </div>
    </div>
</template>

<script>
    import tileDictionary from '../../utils/tileDictionary';
    import { mapState } from 'vuex';
    import * as images from '../modules/images';
    import * as analytics from './analyticsHelpers';
    import { scroll } from '../../utils/helpers';

    export default {
        components: {
            ...images
        },
        data() {
            return {
                tileDictionary,
                searchTerm:'',
            };
        },
        computed: {
            ...mapState({
                isIE: state => state.ui.isIE
            })
        },
        mounted() {
            const { searchTerm } = this.$store.state.ui;
            if (searchTerm) {
                this.$refs.searchTermInput.value = searchTerm;
            }
        },
        methods: {
            scrollToTop() {
                this.$parent.showMobileFilters = false;
                scroll.enable();
                window.scrollTo(0, 160);
            },
            clearSearch() {
                //this.$parent.searchTerm = null;
                this.$store.dispatch('ui/setSearchTerm', null);
                this.$refs.searchTermInput.value = '';
            },
            selectTopic(topicCode) {
                //this.$parent.topicCode = topicCode;
                this.$store.dispatch('ui/setTopicCode', topicCode);
                analytics.selectFilter({ topicCode });
                this.clearSearch();
                this.scrollToTop();
            },

         
            setSearchTermTimeout() {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }                
                this.timer = setTimeout(() => {
                    this.setSearchTerm();
                }, 300);
            },
            setSearchTerm() {
                const { value } = this.$refs.searchTermInput;
                this.searchTerm = value;
                // this.$parent.searchTerm = value; //set debounce here
                this.$store.dispatch('ui/setSearchTerm', value);
                // this.$parent.topicCode = null;
                this.$store.dispatch('ui/setTopicCode', null);
                this.scrollToTop();

                if (value === '') return;
                console.log('analytics: analyticsTimer')
                if (this.analyticsTimer) {
                    clearTimeout(this.analyticsTimer);
                    this.analyticsTimer = null;
                }
                this.analyticsTimer = setTimeout(() => {
                    this.sendSearchTermAnalytics();
                }, 1500);

            },

            sendSearchTermAnalytics() {
                if (this.searchTerm === '') return;
                console.log('analytics: ' + this.searchTerm)
                analytics.searchEnter({ searchTerm: this.searchTerm });
            },
            seeAll() {
                this.clearSearch();
                // this.$parent.topicCode = null;
                this.$store.dispatch('ui/setTopicCode', null);
                analytics.selectSeeAll();
                this.scrollToTop();
            },
            tileIsSelected(name) {
                const { topicCode, searchTerm } = this.$store.state.ui;
                const { topicMemo } = this.$store.state.questions;
                if (topicCode === null && !searchTerm) return true;
                if (topicCode && topicCode === name) return true;
                if (searchTerm && topicMemo[name]) return true;
                // topicMemo is an object like this {C01: true, C02: true}
                return false;
            },
            getTileColor(topicCode) {
                if (this.tileIsSelected(topicCode))
                    return `yrbs-cat__color--${topicCode}`;
                return `yrbs-cat__color--none`;
            },
            getCurrent(topicCode) {
                return tileDictionary[topicCode];
            }
        }
    };
</script>

<style lang="scss">
    @import '../../scss/variables';
    @import '../../scss/breakpoints';

    $smallerThanIpad: 767px;

    .filter {
        $top-padding: 15px;

        &__wrapper {
            border: 2.5px solid $gray;
            margin-top: 5px;
            padding-top: $top-padding;
            background: $light;
            border-radius: 20px;
            height: 760px + $top-padding;

            @media screen and (max-width: $smallerThanIpad) {
                z-index: 1000;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
                height: 100%;
                border-radius: 0;
                border: none;
            }

            &--sticky {
                position: sticky;
                top: -90px - $top-padding;

                @media screen and (max-width: $smallerThanIpad) {
                    position: fixed;
                    top: 0;
                }
            }

            .input-group > .link {
                position: absolute;
                right: 0;
                top: -2rem;
            }
        }

        &__button {
            box-sizing: border-box;
            margin: 5px 0;
            border-radius: 20px;
            min-height: 50px;
            height: 9vh;
            max-height: 70px;
            line-height: 1.2;
            color: $white;
            padding: 13px;
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
                padding: 10px;
                border: 3px solid $light;
            }

            & > div:first-child {
                margin-right: 5px;
            }

            @media screen and (max-width: $smallerThanIpad) {
                min-height: unset;
                height: 50px;
            }
        }

        &__see-all {
            text-decoration: underline;
            text-align: center;

            &:hover {
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
</style>
