import tileDictionary from '../../utils/tileDictionary';

export function getStratTypesString(chartData, selectedYear) {
    // formats a string like this: thing1, thing2, and thing3

    // 1) gather all the strat types from the data
    const stratTypes = {};
    for (let i = 0; i < chartData.length; i++) {
        const current = chartData[i];
        if (current.StratType === 'Total') continue;
        // filter out any strats not included in current year
        if (current.Year !== selectedYear) continue;
        stratTypes[current.StratType] = true;
    }
    // 2) make them an array
    const strats = Object.keys(stratTypes);
    // if just one return it
    if (strats.length === 1) return strats[0].toLowerCase();
    // otherwise start adding it to the formatted String
    let formattedString = '';
    for (let i = 0; i < strats.length; i++) {
        const current = strats[i].toLowerCase();
        if (i === 0) {
            formattedString += current;
        } else if (strats.length - 1 === i) {
            if (strats.length === 2) {
                formattedString += ` and ${current}`;
            } else {
                // because the oxford comma is important :)
                formattedString += `, and ${current}`;
            }
        } else {
            formattedString += `, ${current}`;
        }
    }
    return formattedString;
}

export function getAvailableYear(year, yearOptions) {
    const last = [...yearOptions].sort()[yearOptions.length - 1];
    // make sure that the year is a year contained in the data,
    // otherwise default to the last available year.
    return yearOptions.find(y => year === y) ? year : last;
}

function formatFootnotes(footnotes) {
    let trendDaggarNote = false;
    let trendFootNotes = [];
    let barFootNotes = [];
    const cb = (a, b) => (a.order > b.order ? 1 : -1);
    if (footnotes.length) {
        footnotes.forEach(ft => {
            if (ft.FootnoteSymbol === '†') trendDaggarNote = true;
            const data = {
                symbol: ft.FootnoteSymbol,
                text: ft.FootnoteText.replace('Missing bars', 'No Data'),
                order: ft.DisplayOrder
            };
            ft.StratificationType === 'TrendChart'
                ? trendFootNotes.push(data)
                : barFootNotes.push(data);
        });
        trendFootNotes = trendFootNotes.sort(cb);

        barFootNotes.push({ order: 4, symbol: ' ', text: 'CI = 95% confidence interval which indicates that 95% of the time, the value is expected to fall within this estimated range.' });

        barFootNotes = barFootNotes.sort(cb);
    }

    return {
        trendDaggarNote,
        trendFootNotes,
        barFootNotes
    };
}

export function hasMissingData({ missingData, selectedYear }) {
    if (!Object.keys(missingData).length) return false;
    return missingData[selectedYear];
}

export function formatChartData({
    footnoteData,
    chartData,
    topicCode,
    year,
    shortQuestion,
    isCompare
}) {
    let newData;
    const { color } = tileDictionary[topicCode];
    const dataAvailable =
        !!chartData[0] && !!chartData[0].Strat && !!chartData[0].StratType;
    if (chartData.length && dataAvailable) {
        const yearMemo = {};
        const missingData = {};
        chartData.forEach(datum => {
            yearMemo[datum.Year] = true;
            if (datum.MainValue === '' && footnoteData.length) {
                if (!missingData[datum.Year]) {
                    missingData[datum.Year] = [];
                }
                // BarChart
                missingData[datum.Year] = true;
            }
        });
        const yearOptions = Object.keys(yearMemo).sort((a, b) => +b - +a);
        const selectedYear = getAvailableYear(year, yearOptions);
        newData = {
            chartData,
            nativeDirection: chartData[0].NativeDirection,
            color,
            yearOptions,
            selectedYear,
            missingData,
            conversationalText: createConversationalText(
                lcFirst(shortQuestion),
                footnoteData
            ),
            errorMessage: null,
            ...formatFootnotes(footnoteData)
        };
    } else {
        newData = {
            chartData: [],
            nativeDirection: '',
            color,
            yearOptions: year ? [year] : [],
            selectedYear: '',
            conversationalText: '',
            errorMessage: 'No Data Available',
            trendDaggarNote: false,
            missingData: {},
            trendFootNotes: [],
            barFootNotes: []
        };
    }
    const returnValue = (isCompare) ? { 'chartData2': newData } : newData;
    return returnValue;
}

const ignore = ["STD", "HIV", "PE", "IUD", "", "", "", "", "", "", "", "", "",]
function lcFirst(string) {
    if (typeof string !== 'string') {
        return ''
    }

    
    let firstWord = string.split(' ')[0];
    if (ignore.includes(firstWord)) {
        return string;
    }
    if (string.length === 0) {
        return string
    }

    return string[0].toLowerCase() + string.slice(1)
}

function createConversationalText(shortQuestion, footnoteData) {
    let yearStart = '';
    let yearEnd = '';
    const trendFootnote = footnoteData.find(note => {
        if (note.Yr) {
            yearStart = note.Yr;
            yearEnd = note.EndYr;
        }
        return !!note.Yr;
    });
    function sentence(direction) {
        return `We are tracking the prevalence of ${shortQuestion} over time. We observed ${direction} from ${yearStart} to ${yearEnd}.`;
    }
    if (trendFootnote) {
        // Get just the first message which is the linear change.
        const text = trendFootnote.FootnoteText.split('.')[0];
        if (text.match(/decrease/)) {
            return sentence('a decrease');
        }
        if (text.match(/increase/)) {
            return sentence('an increase');
        }
    }
    return `We are tracking the prevalence of ${shortQuestion} over time. Here's what's been happening.`;
}
