<template>    

            <tbody v-bind:id="getTableId()" v-if="showStratItem">
                <tr class="tableData"
                    :id="getLocationCode(stratName)"
                    tabindex="-1"
                    :key="stratName">
                    <th scope="row">
                        <span>United States</span>
                    </th>
                    <!--
                        <DataPercentCI  v-if="!isStateOrLocal && strat !=='Total'"
                                       :key="df"
                                       :percent="'-'"
                                       :ci="'-'" />
                        -->
                        <DataPercentCI v-for="value in orderRowData(tableData)" v-if="!isStateOrLocal"
                                       :key="stratName + value.key"
                                       :percent="value.MainValue ? value.MainValue : '—'"
                                       :ci="value.LowCI ? `${value.LowCI} - ${value.HighCI}` : '—'" />
                    </tr>
                </tbody>

    </template>

    <script>

        import { DataPercentCI } from './tableHelpers';
        import tileDictionary from '../../../utils/tileDictionary';
        import locationDictionary from '../../../utils/locationDictionary';

        export default {
            components: {
                DataPercentCI
            },
            data() {
                return {
                    color: '',
                };
            },
            props: ['strat', 'tableData', 'headers', 'showLocationLink', 'label', 'medianRange', 'id', 'allLocations','textLabel'],
            mounted() {
                const { topicCode } = this.$route.query;
                this.color = topicCode ? tileDictionary[topicCode].color : '';
            },
            updated() {
            },
            computed: {
                isStateOrLocal() {
                    if (this.id === 'state' || this.id === 'local') return true;
                    return false;
                },
                width() {

                    const length = this.strat !== 'Total' ? this.headers.length + 1 : this.headers.length;

                    const colWidth = Math.floor(80 / length / 2);
                    return `width: ${colWidth}%`;
                },
                showStratItem() {
                    if (this.label === this.strat) return true;

                    return false;
                },
            },
            methods: {
                limitArray(arr) {
                    const arr2 = [];

                    for (const key in arr) {
                        const cur = arr[key];

                        arr2[key] = {
                            ...cur
                        };
                    }

                    return arr;
                },
                getTableId() {
                    return this.id;
                },
                orderRowData(stratType) {
                    const obj = this.allLocations && this.strat ==='Total' ? stratType[this.strat] : stratType;
                    const arr = [];
                    let index = 1;
                    for (const key in obj) {
                        const cur = this.allLocations && this.strat !=='Total' ? obj[key].Total : obj[key];
                        const order = index;
                        arr[order - 1] = {
                            key,
                            ...cur
                        };
                        index += 1;
                    }
                    return arr;
                },
                getLocationCode(locationName) {
                    return locationDictionary.byName()[locationName];
                },
            }
        };
    </script>

    <style lang="scss">
        @import '../../../scss/variables';
        $lborder: 1px solid black;
        $bborder: 4px solid black;

        //#national-alllocations thead tr:nth-child(2) {
        //    th:nth-child(1) {
        //        font-size: 20px !important;
        //    }
        //}

        </style>
