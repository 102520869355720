import { select } from 'd3-selection';
import { scaleLinear, scaleBand, scaleOrdinal } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { svgGTranslate, calcOrdinalRange } from '../d3.helpers';

export function createBarChart(vm) {
    const chart = new BarChart(vm);
    chart
        .createXAxis()
        .createYAxis();
}
function calcHeight(dataBySortOrder, margin) {
    let height = margin.top + margin.bottom;
    dataBySortOrder.forEach(category => {
        height += 25;
        category.forEach(() => {
            height += 25;
        });
    });
    return height;
}

function createSVG({
    $el,
    width,
    margin,
    height,
    title,
    description,
    dataBySortOrder,
    barChartId
}) {
    const el = select($el)
        .append('svg')
        .attr('id', barChartId)
        .attr('viewBox', `0 0 ${width +50} ${height}`)
        .attr('width', '100%');

    el.attr('height', calcHeight(dataBySortOrder, margin)); // fix for IE & safari scaling issue
    el.append('title').text(title);
    el.append('desc').text(description);
    return el
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
}

export class BarChart {
    constructor(vm) {
        this.dataBySortOrder = vm.dataBySortOrder;
        this.width = vm.width;
        this.margin = vm.margin;
        this.height = vm.height + 125;
        this.isMobile = vm.isMobile;
        this.color = vm.color;
        this.showCIOnBarGraph = vm.showCIOnBarGraph;
        this.svg = createSVG(vm);
        this.scales = this.createScales(vm);
        this.stratIndex = 0;
        this.rowAccHeight = 0;
        this.barChartId = vm.barChartId;
    }

    createScales(vm) {
        const { dataBySortOrder, width, margin, height } = vm;
        const chartHeight = height - margin.top - margin.bottom;
        const x = scaleLinear()
            .domain([0, 100])
            .range([0, width - margin.right - margin.left]);
        const y = scaleOrdinal()
            //.domain(dataBySortOrder.length)
            .range(calcOrdinalRange(dataBySortOrder, chartHeight));
        const sizes = calcOrdinalRange(dataBySortOrder, chartHeight, false);
        const y1 = dataBySortOrder.map((d, index) => {
            return scaleBand()
                .domain(this.getStrats(d))
                .range([0, sizes[index]])                
        });
        return { x, y, y1 };
    }
    getStrats(d) {
        let strats = [];
        
        strats.push(d[0].StratType === 'Transgender' ? d[0].StratType + " " : d[0].StratType)
        d.forEach((item) => {
            strats.push(item.Strat)
        });
        return strats;
    }
    createXAxis() {
        const { svg, scales, height, margin, width } = this;
        const translation = {
            x: 0,
            y: height - margin.top - margin.bottom - 125
        };

        svgGTranslate(svg, translation)
            .call(axisBottom(scales.x).tickValues([0, 25, 50, 75, 100]).tickSize(0).tickPadding(5))
            .call(g => {
                g.selectAll('.tick:not(:first-of-type) line')
                    .attr('stroke-opacity', 0.2)                    
                    .attr('y2', - height + margin.top + margin.bottom)
                    .selectAll('.tick text')
                    .attr('font-size', 17);
                g.selectAll('.tick text').attr('font-size', 17);
            });
        // add Percentage label
        svg
            .append('text')
            .attr(
                'transform', `translate(${(width - margin.left - margin.right) / 2 - 50}, ${height - margin.top - margin.bottom - 90})`)
            .attr('font-size', '15')
            .attr('font-weight', 'bold')
            .text('Percentage (%)')

        svg.append("line")
            .attr("x1", 0)
            .attr("y1", height - margin.top - margin.bottom - 125)
            .attr("x2", width - margin.left - margin.right)
            .attr("y2", height - margin.top - margin.bottom - 125)
            .attr("stroke-width", '2px')
            .attr("stroke", "black");

        svg.append("line")
            .attr("x1", .5)
            .attr("y1", 0)
            .attr("x2", .5)
            .attr("y2", height - margin.top - margin.bottom - 125)
            .attr("stroke-width", '2px')
            .attr("stroke", "black");

        return this;
    }

    createYAxis() {
        const { svg, scales, dataBySortOrder, color, isMobile, showCIOnBarGraph } = this;

        dataBySortOrder.forEach((group, index) => {
            const translation = {
                x: 0,
                y: index === 0 ? 0 : scales.y(index - 1) 
            };

            this.createDataPoints(
                // returns last
                this.createYAxisLegend(
                    this.createBars({
                        svg,
                        scales,
                        group,
                        index,
                        translation,
                        color,
                        isMobile,
                        showCIOnBarGraph,
                        stratCount: dataBySortOrder.length,
                    }) // returns first
                )
            );
        });
        return this;
    }

    createYAxisLegend({ svg, scales, index, translation, color, group, isMobile }) {

        svgGTranslate(svg, translation)
            .call(axisLeft(scales.y1[index]).tickSize(0))
            .call(g => {

                const getFontSize = function () {
                    if (isMobile) {
                        if (group[0].StratType === 'Race') return 9;
                        return 12;
                    }
                    return 14;
                };

                g.selectAll('.tick text')
                    .attr('font-size', getFontSize())
                    .attr('x', -20)
                    .attr('y', this.stratIndex === 1 ? 0 : 0)

                g.selectAll('.tick:first-of-type')
                    .attr('fill', '#000')
                    .attr('font-size', isMobile ? '14' : '15')
                    .attr('font-weight', 'bold');


                //BEGIN alternating grey/white background code
                //This section of code is very finicky. With any adjustments, test bar charts of various sizes (some locations have more strats than others)
                let sectionHeight = null;
                let currentTick;
                let nextTick;
                g.selectAll('.tick:first-of-type').each(function () {
                    currentTick = this.getBoundingClientRect();//get current tick
                });
                g.selectAll('.tick:last-of-type').each(function () {
                    nextTick = this.getBoundingClientRect();
                });

                //calculate height of section
                sectionHeight = nextTick.y - currentTick.y;
                sectionHeight += this.stratIndex % 2 ? 35 : 0;//to keep it simple, adjust only the grey section
                sectionHeight += isMobile ? 10: 0;//mobile adjustments

                //draw alternating grey/white backgrounds on the strat sections
                g.selectAll('.tick:first-of-type')
                    .append('rect')
                    .attr('fill', this.stratIndex % 2 ? 'f2f2f2' : 'white')
                    .attr('opacity', .08)
                    .attr('x', -300)
                    .attr('y', this.stratIndex % 2  ? -15: 0)//offset the y of the grey section
                    .attr('width', isMobile ? this.width + 70 : this.width -20)
                    .attr('height', sectionHeight)

                this.stratIndex += 1;
                //END alternating grey/white background code
            });

        return { svg, scales, index, translation, color, group };
    }

    createBars({ svg, scales, index, translation, color, group, isMobile, stratCount }) {
        svgGTranslate(svg, translation)
            .selectAll('rect')
            .data(group)
            .enter()
            .append('rect')
            .attr('x', 1)
            .attr('y', d => {
                return scales.y1[index](d.Strat) + 10;
            })
            .attr('height', 15)
            .attr('width', d => scales.x(d.MainValue))
            .attr('fill', color);

        return { svg, scales, index, translation, group, color, isMobile, stratCount };
    }

    createDataPoints({ svg, scales, index, translation, color, group }) {
        if (this.showCIOnBarGraph) {
            //left veritical line
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('line')
                .attr('x1', d => scales.x(d.LowCI))
                .attr('x2', d => scales.x(d.LowCI))
                .attr('y1', d => {
                    return scales.y1[index](d.Strat) + 14;
                })
                .attr('y2', d => {
                    return scales.y1[index](d.Strat) + 21;
                })
                .attr("stroke", "white")
                .attr('stroke-width', d => scales.x(d.MainValue) - 25 === 0 || d.MainValue === '' ? 0 : 1.5)

            //left white horizontal line
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('line')
                .attr('x1', d => scales.x(d.LowCI))
                .attr('x2', d => scales.x(d.MainValue) - 0)
                .attr('y1', d => {
                    return scales.y1[index](d.Strat) + 18;
                })
                .attr('y2', d => {
                    return scales.y1[index](d.Strat) + 18;
                })
                .attr("stroke", "white")
                .attr('stroke-width', d => d.MainValue === '' ? 0 : 1.5)

            //right color horizontal line
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('line')
                .attr('x1', d => scales.x(d.MainValue) - 0)
                .attr('x2', d => scales.x(d.HighCI))
                .attr('y1', d => {
                    return scales.y1[index](d.Strat) + 18;
                })
                .attr('y2', d => {
                    return scales.y1[index](d.Strat) + 18;
                })
                .attr("stroke", color)
                .attr('stroke-width', d => d.MainValue === '' ? 0 : 1.5)


            //right veritical bar
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('line')
                .attr('x1', d => scales.x(d.HighCI))
                .attr('x2', d => scales.x(d.HighCI))
                .attr('y1', d => {
                    return scales.y1[index](d.Strat) + 14;
                })
                .attr('y2', d => {
                    return scales.y1[index](d.Strat) + 21;
                })
                .attr("stroke", color)
                .attr('stroke-width', d => d.MainValue === '' ? 0 : 1.5)

            //bar value
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('text')
                .attr('x', d => d.MainValue === '' ? scales.x(1) : scales.x(d.HighCI) + 8) 
                .attr('y', d => {
                    return scales.y1[index](d.Strat) + 22;
                })

                .text(d => {

                    if (d.MainValue === '') return ' no data';//'\u307f no data \u307f';
                    return '(' + format('.1f')(d.LowCI) + ' - ' + format('.1f')(d.HighCI) + ')';
                })
                .attr('font-weight', 'bold')
                .attr('font-size', '14')
        }
        else {
            svgGTranslate(svg, translation)
                .selectAll('mybar')
                .data(group)
                .enter()
                .append('text')
                .attr('x', d => scales.x(d.MainValue) + 5)
                .attr('y', d => {
                    return scales.y1[index](d.Strat) + 22;
                })
                .attr('fill', '#000')
                .text(d => {

                    if (d.MainValue === '') return 'no data';//'\u307f no data';
                    return format('.1f')(d.MainValue);
                })
                .attr('font-weight', 'bold')
                .attr('font-size', '14')
        }
        return { svg, scales, index, translation, group };
    }
}
