<template>        
        <div :class="shouldPrint">
            <table id="all-locations-national" :aria-label="label" :class="['table table-striped all-location-table', 'totaled']">
                <caption>{{ label || 'total' }}</caption>
                <colgroup>
                    <col style="width: 20%" />
                    <col v-for="name in nationalHeaders" span="2" :style="tableColWidth" :key="name" />
                </colgroup>
                <thead class="theadfixed" id="national-thead">
                    <tr>
                        <td :class="['table-type--none']"></td>
                        <th v-for="name in nationalHeaders"
                            :key="name"
                            scope="colgroup"
                            class="sticky"
                            colspan="2"
                            :style="{
              background: color
            }">{{ name }}</th>
                    </tr>
                    <tr>
                        <th scope="col" :class="[ !label ? 'table-type--none' : '']">{{ nationalTextLabel}}</th>
                        <HeaderPercentCI v-for="name in nationalHeaders" :key="name + '1'" />
                    </tr>
                </thead>

                <NationalAllLocations v-for="(value, name) in nationalTableData"
                                      id="national-tbody"
                                      tabindex="-1"
                                      :label="name"
                                      :textLabel="nationalTextLabel"
                                      :tableData="value"
                                      :allLocations="allLocations"
                                      :headers="nationalHeaders"
                                      :strat="strat"
                                      :key="name"></NationalAllLocations>
            </table>
            <table id="all-locations-state" :aria-label="label" :class="['table table-striped all-location-table', 'totaled']"  v-if="stateTableData">
                <colgroup>
                    <col style="width: 20%" />
                    <col v-for="name in nationalHeaders" span="2" :style="tableColWidth" :key="name" />
                </colgroup>
                <thead id="state-thead">
                    <tr>
                        <td :class="['table-type--none']"></td>
                        <th v-for="name in stateHeaders"
                            :key="name"
                            scope="colgroup"
                            colspan="2"
                            :style="{
                  background: color
                }">{{ name }}</th>
                    </tr>
                    <tr>
                        <th scope="col" :class="[ !label ? 'table-type--none' : '']">{{ stateTextLabel}}</th>
                        <HeaderPercentCI v-for="name in stateHeaders" :key="name + '1'" />
                    </tr>
                </thead>

                <LocalStateAllLocations id="state-tbody"
                                        tabindex="-1"
                                        v-if="stateTableData"
                                        :tableData="stateTableData"
                                        :headers="stateHeaders"
                                        :medianRange="stateMedianRange"
                                        :strat="strat"
                                        :showLocationLink="true"
                                        label="State Survey"
                                        textLabel="State"></LocalStateAllLocations>


            </table>
            <table id="all-locations-local" :aria-label="label" :class="['table table-striped all-location-table', 'totaled']" v-if="localTableData">
                <colgroup>
                    <col style="width: 20%" />
                    <col v-for="name in nationalHeaders" span="2" :style="tableColWidth" :key="name" />
                </colgroup>
                <thead id="local-thead">
                    <tr>
                        <td :class="['table-type--none']"></td>
                        <th v-for="name in stateHeaders"
                            :key="name"
                            scope="colgroup"
                            colspan="2"
                            :style="{
                  background: color
                }">{{ name }}</th>
                    </tr>
                    <tr>
                        <th scope="col" :class="[ !label ? 'table-type--none' : '']">{{ localTextLabel}}</th>

                        <HeaderPercentCI v-for="name in stateHeaders" :key="name + '1'" />
                    </tr>
                </thead>

                <LocalStateAllLocations id="local-tbody"
                                        tabindex="-1"
                                        v-if="localTableData"
                                        :tableData="localTableData"
                                        :headers="stateHeaders"
                                        :medianRange="localMedianRange"
                                        :strat="strat"
                                        :showLocationLink="true"
                                        :label="localTextLabel"
                                        textLabel="State"></LocalStateAllLocations>
            </table>

            <div v-if="!stateTableData" class="alert alert-danger" role="alert">No State Data Available</div>
            <div v-if="!localTableData" class="alert alert-danger" role="alert">No Local Data Available</div>
        </div>
    
    </template>
    <script>

    import tileDictionary from '../../../utils/tileDictionary';
    import locationDictionary from '../../../utils/locationDictionary';
    import NationalAllLocations from './NationalAllLocations.vue';
    import LocalStateAllLocations from './LocalStateAllLocations.vue';
    import { HeaderPercentCI } from './tableHelpers';
    export default {

        components: {
            NationalAllLocations,
            HeaderPercentCI,
            LocalStateAllLocations
        },
        data() {
            return {
                color: '',
                scrollPosition: 0,
                timer: null

            };
        },
        props: ['strat', 'tableData', 'headers', 'showLocationLink', 'medianRange', 'id', 'allLocations', 'shouldPrint',
            'nationalTextLabel', 'nationalTableData', 'nationalHeaders',
            'stateTextLabel', 'stateTableData', 'stateHeaders', 'stateMedianRange',
            'localTextLabel', 'localTableData', 'localHeaders', 'localMedianRange'],
        mounted() {
            const { topicCode } = this.$route.query;
            this.color = topicCode ? tileDictionary[topicCode].color : '';

            //throttled event. Will be ran while user is scrolling.
            this.onScrollThrottle = this.throttle(() => { this.adjustHeaders() }, 5);

            window.addEventListener('scroll', this.onScrollThrottle);
            window.addEventListener('scroll', this.adjustRows);
        },
        unmounted() {
            window.removeEventListener('scroll', this.onScrollThrottle);
            window.removeEventListener('scroll', this.adjustRows);
        },
        watch: {
        },
        updated() {
            this.adjustHeaders()
            this.adjustRows()
        },
        computed: {
            isStateOrLocal() {
                if (this.id === 'state' || this.id === 'local') return true;
                return false;
            },
            tableColWidth() {

                const length = this.nationalHeaders.length;

                const colWidth = Math.floor(80 / length / 2);
                return `width: ${colWidth}%`;
            },

        },
        methods: {
            adjustHeaders() {
                //console.log('changed value:', 'test');

                const stickyHeader = document.getElementsByClassName('row sticky-header__wrapper');

                if (stickyHeader.length > 0)
                    var rect = stickyHeader[0].getBoundingClientRect();

                const nationalTbody = document.getElementById('national-tbody');
                var nationalRect = nationalTbody.getBoundingClientRect();

                if (nationalRect.bottom < (rect.bottom + 75)) {
                    var hideNationalSticky = document.getElementsByClassName('hideNationalSticky');
                    if (hideNationalSticky.length === 0) {
                        document.getElementById('all-locations-national').classList.add('hideNationalSticky');
                    }
                }
                else {
                    document.getElementById('all-locations-national').classList.remove('hideNationalSticky')
                }


                const stateTbody = document.getElementById('state-tbody');

                if (stateTbody) {
                    var stateRect = stateTbody.getBoundingClientRect();
                    document.getElementById("state-thead").style.top = rect.bottom + 'px';



                    if (stateRect.bottom < (rect.bottom + 75)) {
                        var hideStateSticky = document.getElementsByClassName('hideStateSticky');
                        if (hideStateSticky.length === 0) {
                            document.getElementById('all-locations-state').classList.add('hideStateSticky');
                        }
                    }
                    else {
                        document.getElementById('all-locations-state').classList.remove('hideStateSticky')//.add('showNationalSticky');
                    }
                }

                const localTbody = document.getElementById('local-tbody');
                if (localTbody) {
                    var localRect = localTbody.getBoundingClientRect();
                    document.getElementById("local-thead").style.top = rect.bottom + 'px';
                    if (localRect.bottom < (rect.bottom + 75)) {
                        var hideLocalSticky = document.getElementsByClassName('hideLocalSticky');
                        if (hideLocalSticky.length === 0) {
                            document.getElementById('all-locations-local').classList.add('hideLocalSticky');
                        }
                    }
                    else {
                        document.getElementById('all-locations-local').classList.remove('hideLocalSticky')//.add('showNationalSticky');
                    }
                }
            },

            adjustRows() {

                let scrollPosition = Math.round(window.scrollY);
                this.scrollPosition = scrollPosition;

                const stateTBody = document.getElementById('state-tbody')
                if (stateTBody) {
                    const stateTHead = document.getElementById('state-thead')
                    var stateRect = stateTHead.getBoundingClientRect();

                    if (stateTBody && stateTBody.rows) {
                        let stateRows = stateTBody.rows;
                        for (var i = 0; i < stateRows.length; i++) {
                            var itemRect = stateRows[i].getBoundingClientRect();
                            if (itemRect.bottom < stateRect.bottom) {//(scrollPosition - 400)) {
                                stateRows[i].classList.add('hideRow');
                            } else {
                                stateRows[i].classList.remove('hideRow');
                            }
                        }
                    }
                }
                const localTBody = document.getElementById('local-tbody')
                if (localTBody) {

                    const localThead = document.getElementById('local-thead')
                    var localTheadRect = localThead.getBoundingClientRect();

                    if (localTBody && localTBody.rows) {
                        let localRows = localTBody.rows;
                        for (var i2 = 0; i2 < localRows.length; i2++) {
                            var localRect = localRows[i2].getBoundingClientRect();
                            if (localRect.bottom < localTheadRect.bottom) {
                                localRows[i2].classList.add('hideRow');
                            } else {
                                localRows[i2].classList.remove('hideRow');
                            }
                        }
                    }
                }
            },
            throttle(fn, wait) {
                return function (...args) {
                    if (this.timer) {
                        clearTimeout(this.timer); // clear any pre-existing timer
                    }
                    const context = this; // get the current context
                    this.timer = setTimeout(() => {
                        fn.apply(context, args); // call the function if time expires
                    }, wait);
                }
            },
            limitArray(arr) {
                const arr2 = [];

                for (const key in arr) {
                    const cur = arr[key];

                    arr2[key] = {
                        ...cur
                    };
                }

                return arr;
            },
            getTableId(id) {
                return id;
            },
            orderRowData(stratType) {
                const obj = this.allLocations && this.strat === 'Total' ? stratType[this.strat] : stratType;
                const arr = [];
                let index = 1;
                for (const key in obj) {
                    const cur = this.allLocations && this.strat !== 'Total' ? obj[key].Total : obj[key];
                    const order = index;
                    arr[order - 1] = {
                        key,
                        ...cur
                    };
                    index += 1;
                }
                return arr;
            },
            getLocationCode(locationName) {
                return locationDictionary.byName()[locationName];
            },

            fixTableCellWidth(table1, table2) {
                // container means `thead` or `tbody`
                const sourceContainers = Array.from(table2.children);
                const sourceTrs = sourceContainers.map(container => Array.from(container.children));
                // move second table rows to first table
                sourceTrs.forEach(trs => {
                    trs.forEach(tr => {
                        table1.lastElementChild.appendChild(tr);
                    });
                });
                // fix table cell width
                Array.from(table1.children).forEach(container => {
                    Array.from(container.children).forEach(tr => {
                        Array.from(tr.children).forEach(td => {
                            if (td.style.width) return;
                            const rect = td.getClientRects()[0];
                            td.style.width = `${rect.width}px`;
                        });
                    });
                });
                // move back the second table rows
                sourceTrs.forEach((trs, index) => {
                    const container = sourceContainers[index];
                    trs.forEach(tr => {
                        container.appendChild(tr);
                    });
                });
            }
        }
    };
    </script>

    <style lang="scss">
    @import '../../../scss/variables';
    @import '../../../scss/breakpoints';
    $lborder: 1px solid black;
    $bborder: 4px solid black;

    #all-location-print {
        //display:none;
    }

    .all-location-table thead tr:nth-child(2) {
        th:nth-child(1) {
            font-size: 20px !important;
        }
    }

    .all-location-table thead {
        position: sticky;
        border-bottom: $lborder;
        background: white;
    }

    .hideNationalSticky {
        #national-thead, #national-tbody {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.1s linear,opacity 0.1s linear;
        }
    }

    .hideStateSticky {
        #state-thead, #state-tbody {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.1s linear,opacity 0.1s linear;
        }
    }

    .hideLocalSticky {
        #local-thead, #local-tbody {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.1s linear,opacity 0.1s linear;
        }
    }

    .hideRow {
        visibility: hidden;
    }

    @media print {
        #all-location-print {
            display: block;
        }

        .table {
            thead, th, td {
                font-size: 10px;
                top: 0px;
            }

            th[scope=col]:first-of-type {
                font-size: 12px;
            }

            tbody tr:first-child th:nth-child(1) {
                font-size: 10px;
            }

            tbody, th, td {
                font-size: 10px !important;
            }
        }


        #all-locations-table thead {
            position: initial;
        }

        .hideNationalSticky {
            #national-thead, #national-tbody {
                visibility: visible;
                opacity: 1;
            }
        }

        .hideStateSticky {
            #state-thead, #state-tbody {
                visibility: visible;
                opacity: 1;
            }
        }

        .hideLocalSticky {
            #local-thead, #local-tbody {
                visibility: visible;
                opacity: 1;
            }
        }

        .hideRow {
            visibility: visible;
        }
    }

    </style>
