<template
  ><svg
    id="Layer_1"
    data-name="Layer 1"
    class="svg-tile-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <title>
      Alcohol and Other Drug Use
    </title>
    <path
      :fill="color"
      d="M20.14,7.33c-.15-1.78-.33-3.4-.5-5.18,0-.29-.08-.58-.11-.87A1.25,1.25,0,0,0,19.12.5H1.83a1.3,1.3,0,0,0-.47,1.11c-.2,2-.41,3.86-.58,5.87s-.19,4-.22,6a10.4,10.4,0,0,0,1.07,4.69l-.32-.79a10.17,10.17,0,0,0,5.76,5.93,13.74,13.74,0,0,0,1.83.52c.16,0,.26.09.28.25s.07.56.08.85c.06,2.93.13,5.24.17,8.17,0,.81-.06,1.62-.13,2.43a2.14,2.14,0,0,1-1.66,1.93C7.05,37.6,3,39.06,3.2,39.27l.18.23h14.2l.18-.23c.18-.21-3.85-1.67-4.44-1.85a2.14,2.14,0,0,1-1.66-1.93,22,22,0,0,1-.13-2.43c0-2.93.11-5.24.17-8.17,0-.29.05-.57.08-.85s.12-.21.28-.25a13.74,13.74,0,0,0,1.83-.52,10.17,10.17,0,0,0,5.76-5.93,11.11,11.11,0,0,0,.73-4.11C20.4,11.26,20.31,9.3,20.14,7.33ZM1.92,9V8.71C2,7.36,2.14,6,2.27,4.65,2.35,3.72,2.46,3,2.55,2c0-.19.1-.23.27-.23,1.2,0,10.8,0,14.4,0l.91,0c.17,0,.24.06.26.23.07.81.17,1.46.24,2.28.12,1.33.24,2.66.35,4,0,.23,0,.47,0,.71Z"
    />
    <path
      :fill="color"
      d="M33,18.21a5.23,5.23,0,0,0-7.41,0l-9.38,9.38A5.24,5.24,0,0,0,23.65,35L33,25.62A5.25,5.25,0,0,0,33,18.21ZM28,28.88l-4.8-4.81-.56.56-.55.56-3.57,3.57a3.19,3.19,0,0,0,0,4.52.62.62,0,0,1,0,.87.59.59,0,0,1-.44.19.57.57,0,0,1-.43-.19A4.38,4.38,0,0,1,16.37,31a4.43,4.43,0,0,1,1-2.86l.26-.28,3.57-3.57.55-.55.56-.56,3.06-3.06.08-.06,1-1a4,4,0,0,1,5.66,5.66Z"
    />
    <path
      :fill="color"
      d="M38.26,31.41,31.38,38.3a4.91,4.91,0,0,0,6.88-6.89Z"
    />
    <path
      :fill="color"
      d="M30.91,31a4.89,4.89,0,0,0-.4,6.47l6.88-6.88A4.91,4.91,0,0,0,30.91,31Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['color']
};
</script>
