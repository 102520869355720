<template>
  <div aria-hidden="true" class="observer" />
</template>

<script>
export default {
  props: ['offsetTop', 'elementSelector'],
  data: () => ({
    observer: null
  }),
  mounted() {
    if (
      !(
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
      )
    )
      return;
    this.observer = new IntersectionObserver(
      ([entry]) => {
        const { y, top } = entry.boundingClientRect;
        const fromTop = y || top; // Edge does not have y defined, only top
        if (fromTop < 0) {
          this.$emit('onScreen');
        } else {
          this.$emit('offScreen');
        }
      },
      { root: null, rootMargin: '0px', threshold: this.createThreshold() }
    );
    const element =
      this.elementSelector && document.querySelector(this.elementSelector);
    this.observer.observe(element || this.$el);
  },
  methods: {
    createThreshold() {
      const arr = [];
      for (let i = 0; i < 1; i += 0.01) {
        arr.push(i);
      }
      return arr;
    }
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>
